angular.module('ireactApp.feeds', ['ireactApp.controllers'])

    /**
     * Feed
     */
    .controller('FeedController', ['$scope', '$http', 'Upload', '$filter', '$timeout', function ($scope, $http, Upload, $filter, $timeout) {
        $scope.base64Img = null;
        $scope.editingFullscreenImg = false;

        $scope.feed_item = {};
        $scope.loading = false;
        $scope.saving = false;
        $scope.active_view = 'list';
        $scope.fullscreen = false;
        $scope.fullscreen_item = {};
        $scope.projects = [];
        $scope.stores = [];
        $scope.custom_tag = { name: "" };
        $scope.is_mobile = false;
        $scope.is_editing = false;
        $scope.edit_tag = false;

        //Mobile
        if (md.mobile()) {
            $scope.files_limit = 4;
            $scope.is_mobile = true;
        } else {
            $scope.files_limit = 12;
            $scope.is_checked = false
        }

        $scope.openMenu = function($mdOpenMenu, ev) {
            originatorEv = ev;
            $mdOpenMenu(ev);
        };

        $scope.$watch('approved', function (approved) {
            if (approved == true)
                success_toast('Files approved');
        });

        $scope.$watch('feed', function (feed) {
            $scope.loading = true;
            return $http({
                method: "GET",
                url: laroute.route('feeds.show', {
                    feeds: feed
                })
            }).success(function (data) {
                $scope.projects = data.projects;
                $scope.tags = data.tags;

                $scope.projects.unshift({ name: 'All Projects', value: 'all', stores: [] });
                data.feed_item.expanded = $scope.feed_item.expanded ? $scope.feed_item.expanded : false;
                $scope.feed_item = data.feed_item;

                if ($scope.feed_item.project_id) {
                    for (var i = 0; i < $scope.projects.length; i++) {
                        if ($scope.projects[i].id == $scope.feed_item.project_id) {
                            $scope.project = $scope.projects[i];
                            $scope.stores = angular.copy($scope.projects[i].stores);
                            break;
                        }
                    }
                } else {
                    $scope.project = $scope.projects[0];
                }

                $scope.stores.unshift({ name: 'All Stores', value: 'all' });

                if ($scope.feed_item.store_id) {
                    for (var i = 0; i < $scope.stores.length; i++) {
                        if ($scope.stores[i].id == $scope.feed_item.store_id) {
                            $scope.store = $scope.stores[i];
                            break;
                        }
                    }
                } else {
                    $scope.store = $scope.stores[0];
                }

                for (var i = 0; i < $scope.tags.length; i++) {
                    if ($scope.tags[i].id == $scope.feed_item.tag_id) {
                        $scope.tag = $scope.tags[i];
                        break;
                    }
                }

                $scope.tags.push({ name: 'custom...' });
                $scope.loading = false;
            });
        });

        //Show Delete
        $scope.showDelete = function (feed_item) {
            feed_item.show_delete = true;
        };
        $scope.hideDelete = function (feed_item) {
            if (md.mobile()) {
                feed_item.show_delete = true;
            } else {
                feed_item.show_delete = false;
            }
        };
        $scope.checkShowDelete = function () {
            if (md.mobile()) {
                return true;
            }
            return false;
        };

        //Slider and Expand Feature
        $scope.toggleFeedItem = function (feed_item, event) {
            if ($(event.target).is('a')
                || ($(event.target).is('img') && $(event.target).parent().is('a'))
                || $(event.target).hasClass('ir-slide')
            )
                return;

            feed_item.expanded = feed_item.expanded ? false : true;
        };

        $scope.setCurrentSlideIndex = function (index) {
            $scope.fullscreen_item.current_index = index;
        };

        $scope.isCurrentSlideIndex = function (feed_item, index) {
            return feed_item.current_index === index;
        };

        $scope.prevSlide = function (id, feed_item, files, event) {
            $('#ir-slider-' + id + ' .ir-slide').removeClass('ir-slide-animation-left');
            $('#ir-slider-' + id + ' .ir-slide').addClass('ir-slide-animation-right');
            feed_item.current_index = (feed_item.current_index < files.length - 1) ? ++feed_item.current_index : 0;
            $scope.setCurrentSlideIndex(feed_item.current_index);
            event.stopPropagation();
        };

        $scope.nextSlide = function (id, feed_item, files, event) {
            $('#ir-slider-' + id + ' .ir-slide').removeClass('ir-slide-animation-right');
            $('#ir-slider-' + id + ' .ir-slide').addClass('ir-slide-animation-left');
            feed_item.current_index = (feed_item.current_index > 0) ? --feed_item.current_index : files.length - 1;
            $scope.setCurrentSlideIndex(feed_item.current_index);
            event.stopPropagation();
        };

        //Fullscreen
        $scope.showFullscreen = function (feed_item, index, event) {
            $('body').css({
                overflow: 'hidden'
            });
            $scope.fullscreen = true;
            $scope.fullscreen_item = feed_item;
            $scope.setCurrentSlideIndex(index);
            event.stopPropagation();
        };

        $scope.showFullscreenById = function (feed_item, id, event) {
            $('body').css({
                overflow: 'hidden'
            });
            $scope.fullscreen = true;
            $scope.fullscreen_item = feed_item;
            for (var i = 0; i < feed_item.files.length; i++) {
                if (feed_item.files[i].id == id) {
                    $scope.setCurrentSlideIndex(i);
                    break;
                }
            }
            event.stopPropagation();
        };

        $scope.compressFullscreen = function () {
            $('body').css({
                overflow: ''
            });
            $scope.fullscreen = false;
            $scope.fullscreen_item = {};

        };

        $('#ir-fullscreen .ir-fullscreen-container').css({
            height: $(window).height()
        });
        $(window).on('resize', function () {
            $('#ir-fullscreen .ir-fullscreen-container').css({
                height: $(window).height()
            });
        });

        //edit image
        $scope.editFullscreenImage = function () {
            $scope.loading = true;
            $scope.base64Img = '';

            var index = $scope.fullscreen_item.current_index;
            var url = $scope.fullscreen_item.files[index].file_url;
            $scope.editFilename = $scope.fullscreen_item.files[index].file_name;

            return $http({
                method: "GET",
                url: laroute.route('main.getBase64UriFromImage') + '?url=' + url
            }).success(function (data) {
                if (data) {
                    $scope.base64Img = data;
                    $scope.editingFullscreenImg = true;
                } else {
                    $scope.editingFullscreenImg = false;
                }
                $scope.loading = false;
            });

        };

        $scope.closeEditImg = function () {
            $scope.editingFullscreenImg = false;
        };

        $scope.saveImgEdited = function () {
            if ($scope.editFilename) {
                var index = $scope.fullscreen_item.current_index;
                var canvas = $('.darkroom-image-container .lower-canvas').get(0);
                var dataURL = canvas.toDataURL("image/png");
                $scope.loading = true;

                return $http({
                    method: "POST",
                    url: laroute.route('dashboard.update_feed_image'),
                    data: {
                        image: dataURL,
                        filename: $scope.editFilename,
                        old_file: $scope.fullscreen_item.files[index].id
                    }
                }).success(function (data) {
                    $scope.loading = false;
                    success_toast('Image changes saved');
                    $scope.fullscreen_item.files[index].clean_file_url = data.file_url;
                    $scope.fullscreen_item.files[index].file_url = data.file_url;
                    $scope.fullscreen_item.files[index].file_name = $scope.editFilename;
                    $scope.updateFilesInfo($scope.fullscreen_item.files[index].id, $scope.fullscreen_item.files[index]);
                    $scope.editingFullscreenImg = false;
                });
            }
            else {
                error_toast('You should type a filename');
            }
        };

        $scope.updateFilesInfo = function (id, file) {
            if ($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {
                    if (feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {
                            if (files_to_update.id == id) {
                                files_to_update.file_url = file.file_url;
                                files_to_update.clean_file_url = file.clean_file_url;
                                files_to_update.file_name = file.file_name;
                                files_to_update.filtered_comments = file.filtered_comments;
                                files_to_update.comments_count = file.comments_count;
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (files_to_update.id == id) {
                        files_to_update.file_url = file.file_url;
                        files_to_update.clean_file_url = file.clean_file_url;
                        files_to_update.file_name = file.file_name;
                        files_to_update.filtered_comments = file.filtered_comments;
                        files_to_update.comments_count = file.comments_count;
                    }
                });
            }
        };

        $scope.createComment = function (index) {
            if ($scope.fullscreen_item.files[index]) {
                $scope.saving = true;
                $('.ir-comment-field').blur();
                $http({
                    method: "POST",
                    url: laroute.route('comments.store'),
                    data: {
                        text: $scope.fullscreen_item.comment_text,
                        entity_id: $scope.fullscreen_item.files[index].id,
                        entity: 'post_file'
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    $scope.fullscreen_item.comment_text = '';
                    $scope.fullscreen_item.files[index].filtered_comments = data.comments;
                    $scope.fullscreen_item.files[index].comments_count = data.comments.length;
                    $scope.updateFilesInfo($scope.fullscreen_item.files[index].id, $scope.fullscreen_item.files[index]);
                    success_toast('Comment Saved');
                }).error(function (data) {
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.createReply = function (comment) {
            if (comment.commentable_id) {
                $scope.saving = true;
                $('.ir-reply-field').blur();
                $http({
                    method: "POST",
                    url: laroute.route('comments.store'),
                    data: {
                        text: comment.reply_text,
                        entity_id: comment.commentable_id,
                        entity: 'post_file',
                        parent_id: comment.id
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    comment.reply_text = '';
                    comment.filtered_replies = data.comments;
                    success_toast('Reply Saved');
                }).error(function (data) {
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.approveFile = function (index) {
            $scope.fullscreen_item.show_save_changes = true;
            $scope.fullscreen_item.files[index].action = 'approve';
        };

        $scope.rejectFile = function (index) {
            $scope.fullscreen_item.show_save_changes = true;
            $scope.fullscreen_item.files[index].action = 'reject';
        };

        $scope.saveFilesChanges = function () {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.approve_reject_files'),
                data: {
                    files: $scope.fullscreen_item.files
                }
            }).success(function (data) {
                $scope.saving = false;

                if ($scope.fullscreen_item.post)
                    $scope.fullscreen_item.post.status = data.post.status;

                $scope.fullscreen_item.files = data.files;
                $scope.fullscreen_item.show_save_changes = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('Files Updated');
            });
        };

        //Delete Feed Item
        $scope.deletePost = function () {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('dashboard.delete_feed', {
                    feeds: $scope.feed_item.id
                })
            }).success(function (data) {
                $scope.saving = false;
                success_toast('Post deleted');
                window.location.href = laroute.route('stores.show', { id: $scope.store.id, project: $scope.project.id });
            });
        };

        //Approve / Reject All
        $scope.setApproveVal = function (index, event) {
            $scope.approveAll();
            event.stopPropagation();
        };
        $scope.setRejectVal = function (index, event) {
            $scope.rejectAll();
            event.stopPropagation();
        };
        $scope.approveAll = function () {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.approve_all_files', {
                    posts: $scope.feed_item.post.id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('All Approved');
            });
        };
        $scope.rejectAll = function () {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.reject_all_files', {
                    posts: $scope.feed_item.post.id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('All Rejected');
            });
        };

        $scope.updatePostsAndFilesStatus = function (id, status, post_id, post_status, files) {
            if (post_id && post_status) {
                if ($scope.feed_item.post && $scope.feed_item.post.id == post_id) {
                    $scope.feed_item.post.status = post_status;
                }
            }

            if ($scope.feed_item.files) {
                $scope.feed_item.files.forEach(function (files_to_update) {

                    if (id && status && files_to_update.id == id) {
                        files_to_update.status = status;
                    }

                    if (files) {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].id == files_to_update.id) {
                                files_to_update.status = files[i].status;
                            }
                        }
                    }
                });
            }
        };

        //edit post
        $scope.confirmChangeProject = function () {
            $("#confirm-change-project").openModal();
        };

        //edit post
        $scope.confirmChangeStore = function () {
            $("#confirm-change-store").openModal();
        };

        //edit post
        $scope.cancelChange = function () {
            location.reload();
        };

        //edit post
        $scope.changeProject = function () {
            $scope.stores = [];
            for (var i = 0; i < $scope.projects.length; i++) {
                if ($scope.projects[i].id == $scope.project.id) {
                    $scope.stores = angular.copy($scope.projects[i].stores);
                    break;
                }
            }
            $scope.stores.unshift({ name: 'All Stores', value: 'all' });
            $scope.store = $scope.stores[0];

            $scope.feed_item.project_id = $scope.project.id ? $scope.project.id : null;
            $scope.feed_item.store_id = null;

            $scope.is_editing = true;
        };

        $scope.changeStore = function () {
            $scope.feed_item.store_id = $scope.store.id ? $scope.store.id : null;
            $scope.is_editing = true;
            //Change Tags
            $scope.getTags();

        };

        $scope.getEditing = function () {
            if ($scope.is_editing && typeof ($scope.store.id) != 'undefined') {
                if ($scope.tag.name == 'custom...') {
                    if ($scope.custom_tag.name != '') {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
            return false;
        };

        $scope.changeTag = function () {
            $scope.edit_tag = true;
            $scope.is_editing = true;
            $scope.tag_update = 'post';
        };

        $scope.updateTag = function (tag_update) {
            $scope.tag_update = tag_update;
            if ($scope.tag.name == 'custom...') {
                $http({
                    method: "POST",
                    url: laroute.route('tags.store'),
                    data: {
                        client_id: $scope.feed_item.client_id ? $scope.feed_item.client_id : null,
                        project_id: $scope.feed_item.project_id ? $scope.feed_item.project_id : null,
                        store_id: $scope.feed_item.store_id ? $scope.feed_item.store_id : null,
                        name: $scope.custom_tag.name
                    }
                }).success(function (data) {
                    $scope.feed_item.tag_id = data.tag.id ? data.tag.id : null;
                    $('#edit-tag-post').closeModal();
                    $scope.updateFeedItem();
                });
            } else {
                $scope.feed_item.tag_id = $scope.tag.id ? $scope.tag.id : null;
                $('#edit-tag-post').closeModal();
                $scope.is_editing = true;
                $scope.updateFeedItem();
            }
        };

        $scope.previousUpdateFeed = function () {
            if ($scope.edit_tag) {
                $('#edit-tag-post').openModal();
            } else {
                $scope.updateFeedItem();
            }
        };

        $scope.updateFeedItem = function () {
            $scope.loading = true;
            return $http({
                method: "PUT",
                url: laroute.route('feeds.update', {
                    feeds: $scope.feed_item.id,
                }),
                data: {
                    project_id: $scope.feed_item.project_id,
                    store_id: $scope.feed_item.store_id,
                    tag_id: $scope.feed_item.tag_id,
                    tag_update: $scope.tag_update ? $scope.tag_update : 'post'
                }
            }).success(function (data) {
                $scope.feed_item = data.feed_item;
                $scope.edit_tag = false;
                $scope.feed_item.tag = { name: $scope.tag.name };
                $scope.feed_item.store = $scope.store;
                $scope.feed_item.project = $scope.project;
                $scope.tags = data.tags;
                $scope.tag = $scope.tags[0];
                $scope.tag_update = 'post';
                $scope.edit_tag = false;
                $scope.is_editing = false;

                success_toast('Post Updated');

                for (var i = 0; i < $scope.tags.length; i++) {
                    if ($scope.tags[i].id == $scope.feed_item.tag_id) {
                        $scope.tag = $scope.tags[i];
                        break;
                    }
                }

                $scope.loading = false;
            });
        };

        $scope.closeFullscreen = function (event) {
            if (!$(event.target).is("#ir-fullscreen"))
                return;
            $scope.closeEditImg();
            $scope.compressFullscreen();
        };

        $scope.getTags = function () {
            $scope.loading = true;
            $http({
                method: "GET",
                url: laroute.route('tags.get_data', {
                    client: $scope.feed_item.client_id,
                    project: $scope.feed_item.project_id,
                    store: $scope.feed_item.store_id,
                    order_dir: $scope.order_dir
                })
            }).success(function (data) {
                $scope.tags = data.tags;
                $scope.loading = false;
            });
        };

        $scope.setDeleteVal = function (index) {
            $scope.index = index;
            $('#delete-post').openModal();
        };

        // Edit Post Feed
        $scope.post = {
            files: []
        };

        $scope.removeFile = function (file) {
            $scope.max_files_error = false;
            $scope.invalid_files = [];

            var fileIndex = $scope.post.files.indexOf(file);
            $scope.post.files = $filter('filter')($scope.post.files, function (value, index) { return index !== fileIndex; });
        };

        $scope.prepareUpdatePost = function () {
            $scope.saving = true;
            if ($scope.post.files) {
                $scope.post.files = $filter('orderBy')($scope.post.files, 'name');
            }
            Upload.upload({
                url: laroute.route('posts.add_files'),
                method: 'POST',
                sendFieldsAs: 'form',
                fields: {
                    post_id: $scope.feed_item.post.id,
                    post: $scope.post,
                    feed_item_id: $scope.feed
                }
            }).success(function (data) {
                $scope.getFeedItems();
                $scope.post.files = [];
                $scope.invalid_files = [];
                success_toast('Image(s) Uploaded');
                $scope.saving = false;
                // $scope.$parent.saving = false;

                if (md.mobile()) {
                    $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
                }
                $scope.loading = false;
            }).error(function (data) {
                $scope.saving = false;
                error_toast(data.errors);
                $scope.loading = false;
            });
        };

        $scope.getFeedItems = function () {
            $scope.loading = true;
            return $http({
                method: "GET",
                url: laroute.route('feeds.show', {
                    feeds: $scope.feed
                })
            }).success(function (data) {
                $scope.feed_item = data.feed_item;
                $scope.loading = false;
            });
        };
    }])

    .animation('.ir-slide-animation-right', function () {
        return {
            addClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    TweenMax.to(element, 0.5, { left: -element.parent().width(), onComplete: done });
                }
                else {
                    done();
                }
            },
            removeClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    element.removeClass('ng-hide');

                    TweenMax.set(element, { left: element.parent().width() });
                    TweenMax.to(element, 0.5, { left: 0, onComplete: done });
                }
                else {
                    done();
                }
            }
        };
    })
    .animation('.ir-slide-animation-left', function () {
        return {
            addClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    TweenMax.to(element, 0.5, { left: element.parent().width(), onComplete: done });
                }
                else {
                    done();
                }
            },
            removeClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    element.removeClass('ng-hide');

                    TweenMax.set(element, { left: -element.parent().width() });
                    TweenMax.to(element, 0.5, { left: 0, onComplete: done });
                }
                else {
                    done();
                }
            }
        };
    });