angular.module('ireactApp.clients', ['ireactApp.controllers'])

    /**
     * Clients Index
     */
    .controller('ClientsIndexController', ['$scope', '$http', 'Upload', function ($scope, $http, Upload) {

        $scope.clients = [];
        $scope.loading = true;
        $scope.saving = false;
        $scope.order_by = 'name';
        $scope.order_dir = 'asc';

        $scope.getClients = function()
        {
            $scope.loading = true;
            $http({
                method : "GET",
                url : laroute.route('clients.get_data', { order_by: $scope.order_by, order_dir: $scope.order_dir })
            }).success(function(data){
                $scope.clients = data.clients;
                $scope.loading = false;
            });
        };

        $scope.orderBy = function(order_by)
        {
            if (order_by == $scope.order_by) {
                $scope.order_dir = $scope.order_dir == 'asc' ? 'desc' : 'asc';
            } else {
                $scope.order_dir = 'asc';
            }
            $scope.order_by = order_by;
            $scope.getClients();
        };

        $scope.updateClientLogo = function(index, file, invalid_files) {
            if (invalid_files.length == 0) {

                if (!file) {
                    $scope.clients[index].logo = null;
                }

                Upload.upload({
                    url: laroute.route('clients.update_logo', {clients: $scope.clients[index].id}),
                    method: 'POST',
                    sendFieldsAs: 'form',
                    file: file
                }).success(function (data, status, headers, config) {
                    $scope.clients[index].logo = data.client.logo;
                    if (file)
                        success_toast('Logo Updated');
                }).error(function (data) {
                    error_toast(data.errors);
                });
            }
        };

        $scope.saveClients = function() {

            if ($scope.frmManageClients.$valid) {
                $scope.saving = true;
                $http({
                    method: "PUT",
                    url: laroute.route('clients.update_all'),
                    data: {
                        clients: $scope.clients,
                        order_by: $scope.order_by,
                        order_dir: $scope.order_dir
                    }
                }).success(function (data) {
                    $scope.clients = data.clients;
                    $scope.saving = false;
                    success_toast('Clients updated');
                }).error(function (data){
                    $scope.saving = false;
                    angular.forEach(data.errors, function (errors, key) {
                        error_toast(errors.errors);
                    });
                });
            } else {
                $scope.frmManageClients.submitted = true;
            }
        };

        $scope.setDeleteVal = function(index){
            $scope.index = index;
            $('#delete-client').openModal();
        };

        $scope.deleteClient = function()
        {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('clients.destroy', {
                    clients: $scope.clients[$scope.index].id,
                    order_by: $scope.order_by,
                    order_dir: $scope.order_dir
                })
            }).success(function (data) {
                $scope.clients = data.clients;
                $scope.saving = false;
                success_toast('Client deleted');
            });
        };

        $scope.getClients();
    }])

	/**
     * New Client
     */
    .controller('NewClientController', ['$scope', '$http', 'Upload', function ($scope, $http, Upload) {
        $scope.saving = false;
        $scope.saved = false;

        $scope.createClient = function() {

            if ($scope.frmNewClient.$valid) {
                $scope.saving = true;
                Upload.upload({
                    url: laroute.route('clients.store'),
                    method: 'POST',
                    sendFieldsAs: 'form',
                    fields: {
                        client: $scope.client
                    }
                }).success(function (data, status, headers, config) {
                    $scope.saving = false;
                    $scope.saved = true;
                    $scope.client = data.client;
                }).error(function (data){
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            } else {
                $scope.frmNewClient.submitted = true;
            }
        };
    }])