angular.module('ireactApp.dashboard-admin', ['ireactApp.controllers'])

/**
 * Project Index
 */
    .controller('DashboardAdminController', ['$scope', '$http', 'orderByFilter', function ($scope, $http, orderBy) {
        $scope.date_format = date_format; //global variable at master layout
        $scope.loading = true;
        $scope.filtering = false;
        $scope.clients = {};
        $scope.order_by = 'name';
        $scope.order_dir = true;

        $scope.client_id = null;
        $scope.project_id = null;
        $scope.store_id = null;
        $scope.tableColumns = {
            name: false,
            number: false,
            mall_name: false,
            address: false,
            city: false,
            state: false,
            country: false,
            survey_upload: true,
            install_check_in: true,
            install_check_out: true,
            revisit_needed: true,
            notes: true
        };

        $scope.getClients = function()
        {
            $scope.loading = true;
            return $http({
                method : "GET",
                url : laroute.route('clients.get_data', {
                    sort: $scope.order_by,
                    sort_dir: $scope.order_dir
                })
            }).success(function(data) {
                $scope.clients = data.clients;
                $scope.orderBy('name');
                $scope.loading = false;
            });
        };

        $scope.orderBy = function(sortField)
        {
            $scope.order_dir = (sortField !== null && $scope.order_by === sortField)
                ? !$scope.order_dir : false;

            $scope.clients = orderBy($scope.clients, sortField, $scope.order_dir);
            $scope.order_by = sortField;
        };

        $scope.click = function(id){
            $scope.client_id = id;
            //saveSettings();
            //no longer needed but if we need to save last client|project|store use $scope.$on('setSettings' stores.js

            window.location = laroute.route('clients.index')+'/'+id;
        };

        function saveSettings() {
            $http({
                method : "POST",
                url : laroute.route('dashboard.store_settings'),
                data: {
                    hidden_columns: JSON.stringify($scope.tableColumns),
                    order: 'asc',
                    view: 'list',
                    client: $scope.client_id,
                    project: $scope.project_id,
                    store: $scope.store_id,
                    tag: null
                }
            });
        }

        function getSettings() {
            $scope.loading = true;
            $http({
                method : "GET",
                url : laroute.route('dashboard.get_settings')
            }).success(function(data) {

                if(data.setting) {

                    var tables = [];
                    if(!data.setting.hidden_columns == ''){
                        tables = JSON.parse(data.setting.hidden_columns);
                    }
                    if (tables instanceof Array){
                        //do nothing
                    }else if (tables instanceof Object) {
                        $scope.tableColumns = tables;
                    }

                    var sort = data.setting.order.split(' ');
                    $scope.sort = sort[0];
                    $scope.sort_dir = sort[1];
                    $scope.active_view = data.setting.view;
                    $scope.client_id = data.setting.client;
                    $scope.project_id = data.setting.project;
                    $scope.store_id = data.setting.store;
                    $scope.tag_id = data.setting.tag;

                    $scope.settings = true;

                }
            });
        }

        $scope.$on('setClients', function(e, clients) {
            $scope.clients = clients;
            $scope.loading = false;
        });

    }])
