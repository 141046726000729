angular.module('ireactApp.users', ['ireactApp.controllers'])

	/**
     * Users Index
     */
    .controller('UsersIndexController', ['$scope', '$http', '$mdDialog', function ($scope, $http, $mdDialog) {

        $scope.users = [];
        $scope.roles = [];
        $scope.new_users = [];
        $scope.loading = true;
        $scope.saving = false;
        $scope.order_by = '';
        $scope.order_dir = 'asc';

        $scope.pushUser = function()
        {
            $scope.new_users.push({
                username: '',
                email: '',
                name: '',
                role: '',
                clients: [],
                projects_related: [],
                required: false
            });
        };

        $scope.getUsers = function(initial)
        {
            $scope.loading = true;
            $http({
                method : "GET",
                url : laroute.route('users.get_data', { order_by: $scope.order_by, order_dir: $scope.order_dir })
            }).success(function(data){
                $scope.users = data.users;
                if (initial) {
                    $scope.roles = data.roles;
                    $scope.pushUser();
                }
                $scope.loading = false;
            });
        };

        $scope.orderBy = function(order_by)
        {
            if (order_by == $scope.order_by) {
                $scope.order_dir = $scope.order_dir == 'asc' ? 'desc' : 'asc';
            } else {
                $scope.order_dir = 'asc';
            }
            $scope.order_by = order_by;
            $scope.getUsers(false);
        };

        $scope.addUserRow = function(index)
        {
            if (index == $scope.new_users.length -1 && $scope.isAllFilled(index)) {
                $scope.pushUser();
            }
        };

        $scope.isAllFilled = function(index)
        {
            if (($scope.new_users[index].username  && $scope.new_users[index].username != '') &&
                ($scope.new_users[index].email  && $scope.new_users[index].email != '') &&
                ($scope.new_users[index].name  && $scope.new_users[index].name != '') &&
                ($scope.new_users[index].role  && $scope.new_users[index].role != '') ) {
                return true;
            }
            return false;
        };

        $scope.isRequired = function(index)
        {
            if (($scope.new_users[index].username && $scope.new_users[index].username != '' ) ||
                ($scope.new_users[index].email && $scope.new_users[index].email != '' ) ||
                ($scope.new_users[index].name && $scope.new_users[index].name != '' ) ||
                ($scope.new_users[index].role && $scope.new_users[index].role != '' ) ||
                $scope.new_users[index].clients.length > 0) {
                return true;
            } else {
                return false;
            }
        };

        $scope.removeUserRow = function(index)
        {
            $scope.new_users.splice(index, 1);
        };

        $scope.sourceProjects = function (request, response) 
        {
            var projects_results = [];
            var search_text = extractLast(request.term);
            var clients = [];
            angular.forEach(request.model_depends, function (client, key) {
                clients.push(client.id);
            });            
            return $http({
                method: "POST",
                url: laroute.route('projects.search_by_clients', {
                    client_id: clients.toString(),
                    search: search_text
                }),
            }).success(function (data) {
                angular.forEach(data.projects, function (project, key) {
                    projects_results.push({
                        id: project.id,
                        label: project.name,
                        value: project.name,
                        client_id: project.client_id
                    });
                });
                return response(projects_results);
            }).error(function (data){
                error_toast(data.error);
                return response(projects_results);
            });
        };
        
        $scope.sourceClients = function (request, response) 
        {
            var client_results = [];
            return $http({
                method: "GET",
                url: laroute.route('clients.search', {search: extractLast(request.term)}),
            }).success(function (data) {
                angular.forEach(data.clients, function (client, key) {
                    client_results.push({
                        id: client.id,
                        label: client.name,
                        value: client.name
                    });
                });
                return response(client_results);
            });
        };

        $scope.saveUsers = function() 
        {
             if ($scope.frmManageUsers.$valid) {
                $scope.saving = true;
                $http({
                    method: "POST",
                    url: laroute.route('users.store'),
                    data: {
                        users: $scope.users,
                        new_users: $scope.new_users,
                        order_by: $scope.order_by,
                        order_dir: $scope.order_dir
                    }
                }).success(function (data) {
                    $scope.new_users = [];
                    $scope.pushUser();
                    $scope.users = data.users;
                    $scope.saving = false;
                    success_toast('Users saved');
                }).error(function (data){
                    $scope.saving = false;
                    angular.forEach(data.errors, function (errors, key) {
                        error_toast(errors.errors);
                    });
                });
             } else {
                $scope.frmManageUsers.submitted = true;
             }
        };

        $scope.deleteUser = function(index){            
            var confirm = $mdDialog.confirm()
                .title('Remove User')
                .textContent('Are you sure you want to delete it?')
                .ok('Remove')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                var user_id = $scope.users[index].id;
                $scope.saving = true;
                $http({
                    method: "DELETE",
                    url: laroute.route('users.destroy', {
                        users: user_id,
                        order_by: $scope.order_by,
                        order_dir: $scope.order_dir
                    })
                }).success(function (data) {
                    $scope.users = data.users;
                    $scope.saving = false;
                    success_toast('User deleted');
                });
            }, function() {});            
        };

        $scope.changePassword = function(user_id){    
            window.location = laroute.route('users.change_password', {
                user_id: user_id
            });
        };               

        $scope.getUsers(true);
                
    }])

    /**
     * User change password 
     */
    .controller('UsersChangePasswordController', ['$scope', '$http', '$mdDialog', function ($scope, $http, $mdDialog) {

        $scope.saving = false;
        $scope.change_password = false;
        $scope.password = {
            new_password: null,
            password_confirmation: null
        }

        $scope.savePasswordChanged = function() {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('users.store_new_password', {
                    id: $scope.user.id,
                    password: $scope.password.new_password,
                    password_confirmation: $scope.password.password_confirmation
                })
            }).success(function (data) {
                $scope.change_password = true;
                $scope.saving = false;
                success_toast('Password changed');
            }).error(function (data) {
                $scope.change_password = false;
                $scope.saving = false;
                angular.forEach(data.errors,function(e){
                    error_toast(e);                
                });
            });        
        }
                
    }])
