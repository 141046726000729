'use strict';

/* Controllers */
angular.module('ireactApp.controllers', [])
    .config(function($interpolateProvider){
        $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
    })
    .config(function($mdThemingProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('blue', {
                'default': '800'
            })
            .accentPalette('blue', {
                'default': '600'
            });
    });