angular.module('ireactApp.dashboard', ['ireactApp.controllers'])

    /**
     * Dashboard Feed
     */
    .controller('DashboardFeedController', ['$scope', '$http', 'Upload', '$filter', '$sce', '$compile', '$timeout', function ($scope, $http, Upload, $filter, $sce, $compile, $timeout) {
        $scope.base64Img = null;
        $scope.editingFullscreenImg = false;

        $scope.feed_items = [];
        $scope.feed_items_table = [];
        $scope.files = [];
        $scope.loading = true;
        $scope.filtering = false;
        $scope.saving = false;
        $scope.client = {};
        $scope.project = {};
        $scope.store = {};
        $scope.tag = {};
        $scope.post = {};
        $scope.new_post_label = '';
        $scope.active_view = 'table';
        $scope.fullscreen = false;
        $scope.fullscreen_item = {};
        $scope.page = 1;
        $scope.table_page = 1;
        $scope.show_more = true;
        $scope.selectedClient = null;
        $scope.selectedProject = null;
        $scope.selectedStore = null;
        $scope.selectedTag = null;
        $scope.pages = 1;
        $scope.sort = null;
        $scope.sort_dir = null;

        $scope.search_query_object = null;
        $scope.search_query_text = null;
        $scope.search_id = null;
        $scope.search_query = false;
        $scope.search_type = false;

        $scope.is_checked = false;
        $scope.recently_checked = false;
        $scope.check_out_comment = null;
        
        $scope.showColumns = false;
        
        $scope.tableColumns = [
            ["Client", true],
            ["Project", true],
            ["Store", true],
            ["Tag", true],
            ["Update Type", true],
            ["Last Update", true],
            ["Date Added", false],
            ["Added By", false],
            ["Store #", false],
            ["Address/Mall", false],
            ["City/State", false]
        ];
        
        $scope.querySearch = function(query, source) {
            var src = null;
            if(source == 'clients') {
                src = $scope.clients;
            } else if(source == 'projects') {
                src = $scope.projects;
            } else if(source == 'stores') {
                src = $scope.stores;
            } else if(source == 'tags') {
                src = $scope.tags;
            } 
            
            var selectData = _.map(src, function(item) {
                    var id = null;
                    if(item.id) {
                        id = item.id;
                    } else {
                        id = item.value;
                    }
                    return { display: item.name, value: id };
                }
            );
            var results = query ? selectData.filter(createFilterFor(query)) : selectData,
                    deferred;
            if ($scope.simulateQuery) {
                deferred = $q.defer();
                $timeout(function () {
                    deferred.resolve(results);
                }, Math.random() * 1000, false);
                return deferred.promise;
            } else {
                return results;
            }
        };
        
        $scope.searchTextChange = function(text) {};
        
        $scope.selectedItemChange = function(item) {};
        
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(client) {
                var temp1 = (angular.lowercase(client.value.toString()).indexOf(lowercaseQuery) !== -1);
                var temp2 = (angular.lowercase(client.display).indexOf(lowercaseQuery) !== -1);
                return (temp1 || temp2);
            };
        }
        
        $scope.getNumber = function(num) {
            return new Array(num);   
        };
        
        //Mobile
        if (md.mobile()) {
            $scope.files_limit = 4;
        } else {
            $scope.files_limit = 12;
            $scope.is_checked = false;
        }
        
        $scope.colVis = function(index)
        {
            $scope.tableColumns[index][1] = true;
            saveSettings();
        };
        
        $scope.removeCol = function(index)
        {
            $scope.tableColumns[index][1] = false;
            saveSettings();
        };
        
        function saveSettings() {
            if($scope.sort == null) {
                $scope.sort = 'feed_items.updated_at';
                $scope.sort_dir = 'desc';
            }
            $http({
                method : "POST",
                url : laroute.route('dashboard.store_settings'),
                data: {
                    hidden_columns: JSON.stringify($scope.tableColumns),
                    order: $scope.sort + " " + $scope.sort_dir,
                    view: $scope.active_view,
                    client: $scope.client_id,
                    project: $scope.project_id,
                    store: $scope.store_id,
                    tag: $scope.tag_id
                }
            });
        }
        
        function getSettings() {
            $http({
                method : "GET",
                url : laroute.route('dashboard.get_settings')
            }).success(function(data) {
                if(data.setting) {
                    $scope.tableColumns = JSON.parse(data.setting.hidden_columns);
                    var sort = data.setting.order.split(' ');
                    $scope.sort = sort[0];
                    $scope.sort_dir = sort[1];
                    $scope.active_view = data.setting.view;
                    $scope.client_id = data.setting.client;
                    $scope.project_id = data.setting.project;
                    $scope.store_id = data.setting.store;
                    $scope.tag_id = data.setting.tag;
                }
                $scope.getPosts(true, true);
            });
        }
        
        $scope.sortBy = function(col) {
            if($scope.sort == col && $scope.sort_dir == 'asc') {
                $scope.sort_dir = 'desc';
            } else if($scope.sort == col && $scope.sort_dir == 'desc') {
                $scope.sort_dir = 'asc';
            } else {
                $scope.sort_dir = 'asc';
            }
            $scope.sort = col;
            saveSettings();
            $scope.getPosts(false, false);
        };
        
        $scope.updateSortBy = function(col) {
            if($scope.sort == col && $scope.sort_dir == 'asc') {
                $scope.sort_dir = 'desc';
            } else if($scope.sort == col && $scope.sort_dir == 'desc') {
                $scope.sort_dir = 'asc';
            } else {
                $scope.sort_dir = 'asc';
            }
            $scope.sort = col;
        };
        
        $scope.getPosts = function(initial, reset)
        {
            if (reset) {
                $scope.page = 1;
                $scope.show_more = true;
                $scope.feed_items = [];
                $scope.files = [];
            }

            $scope.loading = true;
            return $http({
                method : "GET",
                url : laroute.route('dashboard.get_feed', {
                    client_id: $scope.client_id ? $scope.client_id : '',
                    project_id: $scope.project_id ? $scope.project_id : '',
                    store_id: $scope.store_id ? $scope.store_id : '',
                    tag_id: $scope.tag_id ? $scope.tag_id : '',
                    page: $scope.page,
                    table_page: $scope.table_page,
                    search_id: $scope.search_id ? $scope.search_id : '',
                    query: $scope.search_query ? $scope.search_query : '',
                    type: $scope.search_type ? $scope.search_type : '',
                    view: $scope.active_view,
                    sort: $scope.sort,
                    sort_dir: $scope.sort_dir
                })
            }).success(function(data) {
                var client_index = $scope.clients ? $scope.clients.indexOf($scope.client) : 0;
                var project_index = $scope.projects ? $scope.projects.indexOf($scope.project) : 0;
                var store_index = $scope.stores ? $scope.stores.indexOf($scope.store) : 0;
                var tag_index = $scope.tags ? $scope.tags.indexOf($scope.tag) : 0;

                $scope.clients = data.clients;
                $scope.projects = data.projects;
                $scope.stores = data.stores;
                $scope.tags = data.tags;

                if ($scope.is_admin && !md.phone()) {
                    $scope.clients.unshift({name:'All Clients', value: 'all'}, {name: 'New Client...', value: 'add'}, {name: 'Manage Clients...', value: 'manage'});
                } else {
                    $scope.clients.unshift({name:'All Clients', value: 'all'});
                }

                if ($scope.client_id && $scope.is_admin && !md.phone()) {
                    if ($scope.projects.length > 0) {
                        $scope.projects.unshift({name: 'Manage Projects...', value: 'manage'});
                    }
                    $scope.projects.unshift({name: 'New Project...', value: 'add'});
                }
                if ($scope.client_id && $scope.project_id && $scope.is_admin && !md.phone()) {
                    $scope.stores.unshift({name: 'Manage Stores...', value: 'manage'});
                }
                $scope.projects.unshift({name:'All Projects', value: 'all'});
                $scope.stores.unshift({name:'All Stores', value: 'all'});
                $scope.tags.unshift({name: 'Manage Folders...', value: 'manage'});
                $scope.tags.unshift({name:'All Folders', value: 'all'});
                
                if (initial) {
                    if ($scope.is_client == 1 && $scope.clients.length > 0) {
                        $scope.client = $scope.clients[1];
                        $scope.client_id = $scope.client.id;
                        $scope.setNewPostLabel();
                    } else {
                        if ($scope.client_id) {
                            $scope.client = _.find($scope.clients, function(c){ return c.id == $scope.client_id });
                        } else {
                            $scope.client = $scope.clients[0];
                        }
                    }

                    if ($scope.project_id) {
                        $scope.project = _.find($scope.projects, function(p){ return p.id == $scope.project_id });
                    } else {
                        $scope.project = $scope.projects[0];
                    }

                    if ($scope.store_id) {
                        $scope.store = _.find($scope.stores, function(s){ return s.id == $scope.store_id });
                    } else {
                        $scope.store = $scope.stores[0];
                    }

                    if ($scope.tag_id) {
                        $scope.tag = _.find($scope.tags, function(t){ return t.id == $scope.tag_id });
                    } else {
                        $scope.tag = $scope.tags[0];
                    }

                    $scope.updateFilters();
                } else {
                    $scope.client = $scope.clients[client_index];
                    $scope.project = $scope.projects[project_index];
                    $scope.store = $scope.stores[store_index];
                    $scope.tag = $scope.tags[tag_index];
                }

                $scope.show_more = data.show_more;
                for (var i = 0; i < data.feed_items.length; i++) {
                    $scope.feed_items.push(data.feed_items[i]);
                }
                $scope.feed_items_table = data.feed_items_table;
                
                $scope.pages = Math.floor( data.count_items / 10 ) + 1;
                for (var i = 0; i < data.files.length; i++) {
                    $scope.files.push(data.files[i]);
                }
                
                $scope.loading = false;

                $( ".md-autocomplete-suggestions-container" ).each( function( index, element ){
                    var innerContainer = $( this ).find('.md-virtual-repeat-sizer');
                    if ($( this ).height() == 0) {
                        $(this).height(innerContainer.height());
                    }
                });
            });
        };

        $scope.loadMorePosts = function()
        {
            if($scope.active_view !== 'table') {
                if ($scope.loading)
                    return;

                if ($scope.show_more){
                    $scope.page++;
                    $scope.getPosts(false, false);
                }
            }
        };
        
        $scope.changePage = function(page)
        {
            if(page > 0 && page <= $scope.pages) {
                $scope.table_page = page;
                $scope.getPosts(false, false);
            }
        };

        //Search
        $scope.searchFeeds = function (queryObj, reset) {
            if (reset !== false) {
                reset = true;
            }

            if (queryObj) {
                $scope.search_query_object = queryObj;
                $scope.search_query_text = queryObj.originalObject.query;
                $scope.search_id = $scope.search_query_object.originalObject.id;
                $scope.search_query = $scope.search_query_object.originalObject.query ? $scope.search_query_object.originalObject.query : false;
                $scope.search_type = $scope.search_query_object.originalObject.type ? $scope.search_query_object.originalObject.type : false;

                $scope.getPosts(false, reset);

                if (md.mobile()) {
                    $scope.hideSearch();
                }
            }
        };

        $scope.$on('cleanSearch', function (event) {
            $scope.$broadcast('angucomplete-alt:clearInput');
            $scope.search_query_object = null;
            $scope.search_query_text = null;
            $scope.search_id = null;
            $scope.search_query = false;
            $scope.search_type = false;
            $scope.getPosts(false, true);
            if(md.mobile()){
                $scope.hideSearch();
            }
        });

        //Filters and links
        $scope.selectClient = function(item)
        {
            var client = $scope.clients[0];
            if(item) {
                if(item.value == 'all' || item.value == undefined) {
                    client = $scope.clients[0];
                } else if(item.value == 'add') {
                    client = $scope.clients[1];
                } else if(item.value == 'manage') {
                    client = $scope.clients[2];
                } else {
                    client = _.find($scope.clients, function(c){ return c.id == item.value });
                }
            }
            $scope.client = client;

            //check if it was filtering to not load again posts
            if ($scope.filtering) {
                return;
            }

            //reset project and store
            $scope.project_id = null;
            $scope.store_id = null;
            $scope.project = $scope.projects[0];
            $scope.store = $scope.stores[0];
            
            $scope.updateSortBy('projects.name');
            $scope.sort_dir = "asc";

            if ($scope.client.id) {
                $scope.client_id = $scope.client.id;
                saveSettings();
                $scope.getPosts(false, true).then(function(){
                    $scope.updateFilters();
                });
            } else {
                var option = $scope.client;
                switch(option.value) {
                    case 'all':
                        $scope.client_id = null;
                        saveSettings();
                        $scope.getPosts(false, true).then(function(){
                            $scope.updateFilters();
                        });
                        break;
                    case 'add':
                        window.location.href = laroute.route('clients.create');
                        break;
                    case 'manage':
                        window.location.href = laroute.route('clients.index');
                        break;
                }
            }
            $scope.setNewPostLabel();
        };

        $scope.selectProject = function(item)
        {
            var project = $scope.projects[0];
            if(item) {
                if(item.value == 'all' || item.value == undefined) {
                    project = $scope.clients[0];
                } else if(item.value == 'add') {
                    project = $scope.clients[1];
                } else if(item.value == 'manage') {
                    project = $scope.clients[2];
                } else {
                    project = _.find($scope.projects, function(p){ return p.id == item.value });
                }
            }
            $scope.project = project;

            //check if it was filtering to not load again posts
            if ($scope.filtering) {
                return;
            }
            
            //reset store
            $scope.store_id = null;
            $scope.store = $scope.stores[0];
            $scope.updateSortBy('stores.name');
            $scope.sort_dir = "asc";
            
            if ($scope.client_id) {
                if ($scope.project.id) {
                    $scope.project_id = $scope.project.id;
                    saveSettings();
                    $scope.getPosts(false, true).then(function(){
                        $scope.updateFilters();
                    });
                } else {
                    var option = $scope.project;
                    switch(option.value) {
                        case 'all':
                            $scope.project_id = null;
                            saveSettings();
                            $scope.getPosts(false, true).then(function(){
                                $scope.updateFilters();
                            });
                            break;
                        case 'add':
                            window.location.href = laroute.route('projects.create', {client_id: $scope.client_id});
                            break;
                        case 'manage':
                            window.location.href = laroute.route('projects.index', {client_id: $scope.client_id});
                            break;
                    }
                }

            }
            $scope.setNewPostLabel();
        };

        $scope.selectStore = function(item)
        {
            var store = $scope.stores[0];
            if(item) {
                if(item.value == 'all' || item.value == undefined) {
                    store = $scope.stores[0];
                } else if(item.value == 'manage') {
                    store = $scope.stores[1];
                } else {
                    store = _.find($scope.stores, function(s){ return s.id == item.value });
                }
            }
            $scope.store = store;

            //check if it was filtering to not load again posts
            if ($scope.filtering) {
                return;
            }

            $scope.updateSortBy('feed_items.updated_at');
            $scope.sort_dir = "desc";
            
            if ($scope.client_id || $scope.project_id) {
                if ($scope.store.id) {
                    $scope.store_id = $scope.store.id;
                    saveSettings();
                    $scope.getPosts(false, true).then(function(){
                        $scope.updateFilters();
                    });
                } else {
                    var option = $scope.store;
                    switch(option.value) {
                        case 'all':
                            $scope.store_id = null;
                            saveSettings();
                            $scope.getPosts(false, true).then(function(){
                                $scope.updateFilters();
                            });
                            break;
                        case 'manage':
                            if($scope.project_id) {
                                window.location.href = laroute.route('stores.index', {project_id: $scope.project_id});
                            } else {
                                window.location.href = laroute.route('stores.index', {client_id: $scope.client_id});
                            }
                            break;
                    }
                }

            }
            $scope.setNewPostLabel();
        };

        $scope.selectTag = function(item)
        {
            var tag = $scope.tags[0];
            if(item) {
                if(item.value == 'all' || item.value == undefined) {
                    tag = $scope.tags[0];
                } else if(item.value == 'manage') {
                    tag = $scope.tags[1];
                } else {
                    tag = _.find($scope.tags, function(t){ return t.id == item.value });
                }
            }
            $scope.tag = tag;

            //check if it was filtering to not load again posts
            if ($scope.filtering) {
                return;
            }
            
            if ($scope.tag.id) {
                $scope.tag_id = $scope.tag.id;
                saveSettings();
                $scope.getPosts(false, true);
            } else {
                var option = $scope.tag;
                switch(option.value) {
                    case 'all':
                        $scope.tag_id = null;
                        saveSettings();
                        $scope.getPosts(false, true);
                        break;
                    case 'manage':
                        window.location.href = laroute.route('tags.index');
                        break;
                }
            }
        };

        $scope.clearFilters = function()
        {
            if ($scope.is_client == 0) {
                $scope.client_id = null;
            }
            $scope.project_id = null;
            $scope.store_id = null;
            $scope.tag_id = null;
            $scope.$broadcast('angucomplete-alt:clearInput');
            $scope.search_query_object = null;
            $scope.search_query_text = null;
            $scope.search_id = null;
            $scope.search_query = false;
            $scope.search_type = false;
            $scope.searchClientText = null;
            $scope.searchProjectText = null;
            $scope.searchStoreText = null;
            $scope.searchTagText = null;
            saveSettings();
            $scope.getPosts(true, true);
        };

        $scope.updateFilters = function()
        {
            $scope.filtering = true;
            if ($scope.client && $scope.client_id) {
                $scope.selectedClient = {display: $scope.client.name, value: $scope.client.id};
            } else {
                $scope.selectedClient = null;
            }

            if ($scope.project && $scope.project_id) {
                $scope.selectedProject = {display: $scope.project.name, value: $scope.project.id};
            } else {
                $scope.selectedProject = null;
            }

            if ($scope.store && $scope.store_id) {
                $scope.selectedStore = {display: $scope.store.name, value: $scope.store.id};
            } else {
                $scope.selectedStore = null;
            }

            if ($scope.tag && $scope.tag_id) {
                $scope.selectedTag = {display: $scope.tag.name, value: $scope.tag.id};
            } else {
                $scope.selectedTag = null;
            }

            $timeout(function(){
                $scope.filtering = false;
            }, 1000);
        };

        $scope.filterClient = function(client_id)
        {
            $scope.client_id = client_id;
            $scope.store = $scope.stores[0];
            $scope.store_id = null;
            $scope.project = $scope.projects[0];
            $scope.project_id = null;
            $scope.tag = $scope.tags[0];
            $scope.tag_id = null;
            saveSettings();

            $scope.getPosts(false, true).then(function(){
                $scope.client = _.find($scope.clients, function(c){ return c.id == client_id });
                $scope.updateFilters();
            });
        };

        $scope.filterProject = function(client_id, project_id)
        {
            $scope.client_id = client_id;
            $scope.project_id = project_id;
            $scope.store = $scope.stores[0];
            $scope.store_id = null;
            $scope.tag = $scope.tags[0];
            $scope.tag_id = null;
            saveSettings();

            $scope.getPosts(false, true).then(function(){
                $scope.client = _.find($scope.clients, function(c){ return c.id == client_id });
                $scope.project = _.find($scope.projects, function(p){ return p.id == project_id });
                $scope.updateFilters();
            });
        };

        $scope.filterStore = function(client_id, project_id, store_id)
        {
            $scope.client_id = client_id;
            $scope.project_id = project_id;
            $scope.store_id = store_id;
            $scope.tag = $scope.tags[0];
            $scope.tag_id = null;
            saveSettings();

            $scope.getPosts(false, true).then(function(){
                $scope.client = _.find($scope.clients, function(c){ return c.id == client_id });
                $scope.project = _.find($scope.projects, function(p){ return p.id == project_id });
                $scope.store = _.find($scope.stores, function(s){ return s.id == store_id });
                $scope.updateFilters();
            });
        };

        $scope.filterTag = function(tag)
        {
            $scope.client_id = tag.client_id ? tag.client_id : null;
            $scope.project_id = tag.project_id ? tag.project_id : null;
            $scope.store_id = tag.store_id ? tag.store_id : null;
            $scope.tag_id = tag.id;

            $scope.getPosts(false, true).then(function(){
                $scope.client = _.find($scope.clients, function(c){ return c.id == $scope.client_id });
                $scope.project = _.find($scope.projects, function(p){ return p.id == $scope.project_id });
                $scope.store = _.find($scope.stores, function(s){ return s.id == $scope.store_id });
                $scope.tag = _.find($scope.tags, function(t){ return t.id == $scope.tag_id });
                $scope.updateFilters();
            });
        };

        $scope.setNewPostLabel = function()
        {
            $scope.new_post_label = '';

            if($scope.checkInClient){
                $scope.new_post_label = 'Post to ' + $scope.checkInStore.name + ' store...';
            }
            else if ($scope.client_id && !$scope.project_id && !$scope.store_id) {
                $scope.new_post_label = 'Post to all ' + $scope.client.name + ' projects...';
            } else if ($scope.client_id && $scope.project_id && !$scope.store_id) {
                $scope.new_post_label = 'Post to ' + $scope.project.name + ' project...';
            } else if ($scope.client_id && $scope.store_id) {
                $scope.new_post_label = 'Post to ' + $scope.store.name + ' store...';
            }
        };
        
        $scope.setPostTag = function(tag) {
            $scope.post.tag_id = tag.id;
            $scope.post.custom_tag = '';
        };

        $scope.changeCustomTag = function() {
          if ($scope.post.custom_tag) {
              $scope.post.tag_id = null;
          }
        };

        $scope.createPost = function()
        {
            var $client_id,
                $project_id,
                $store_id;

            if (md.mobile() && $scope.is_contractor) {
                $client_id = $scope.checkInClient ? $scope.checkInClient.id : '';
                $project_id = $scope.checkInProject ? $scope.checkInProject.id : '';
                $store_id = $scope.checkInStore ? $scope.checkInStore.id : '';
            }
            else {
                $client_id = $scope.client_id ? $scope.client_id : '';
                $project_id = $scope.project_id ? $scope.project_id : '';
                $store_id = $scope.store_id ? $scope.store_id : '';
            }

            $scope.saving = true;
            Upload.upload({
                url : laroute.route('posts.store'),
                method: 'POST',
                sendFieldsAs: 'form',
                fields: {
                    post: $scope.post,
                    client_id: $client_id,
                    project_id: $project_id,
                    store_id: $store_id
                }
            }).success(function(data){
                $scope.getPosts(false, true);
                $scope.post.files = [];
                $scope.invalid_files = [];
                $scope.post.message = '';
                $scope.post.custom_tag = '';
                $scope.post.tag_id = null;
                success_toast('Message Posted');
                $scope.saving = false;

                if (md.mobile()) {
                    $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
                }
            }).error(function (data){
                $scope.saving = false;
                error_toast(data.errors);
            });
        };

        $scope.removeFile = function(file) {
            $scope.max_files_error = false;
            $scope.invalid_files = [];

            var fileIndex = $scope.post.files.indexOf(file);
            $scope.post.files = $filter('filter')($scope.post.files, function(value, index) { return index !== fileIndex;});
        };

        $scope.switchView = function(view)
        {
            $scope.active_view = view;
            saveSettings();
        };

        //Show Delete
        $scope.showDelete = function(feed_item)
        {
            feed_item.show_delete = true;
        };
        $scope.hideDelete = function(feed_item)
        {
            if (md.mobile()) {
                feed_item.show_delete = true;
            } else {
                feed_item.show_delete = false;
            }
        };
        $scope.checkShowDelete = function()
        {
            if (md.mobile()) {
                return true;
            }
            return false;
        };

        //Slider and Expand Feature
        $scope.toggleFeedItem = function(feed_item, event) {
            if ($(event.target).is('a')
                || ($(event.target).is('img') && $(event.target).parent().is('a'))
                || ($(event.target).is('i') && $(event.target).parent().is('a'))
                || $(event.target).hasClass('ir-slide')
                )
                return;

            feed_item.expanded = feed_item.expanded ? false : true;
        };
        
        $scope.goToEditPost = function(id) {
            location.href = laroute.url('feed', [id]);
        };

        $scope.setCurrentSlideIndex = function (index) {
            $scope.fullscreen_item.current_index = index;
        };

        $scope.isCurrentSlideIndex = function (feed_item, index) {
            return feed_item.current_index === index;
        };

        $scope.prevSlide = function (id, feed_item, files, event) {
            $('#ir-slider-'+id+' .ir-slide').removeClass('ir-slide-animation-left');
            $('#ir-slider-'+id+' .ir-slide').addClass('ir-slide-animation-right');
            feed_item.current_index = (feed_item.current_index < files.length - 1) ? ++feed_item.current_index : 0;
            $scope.setCurrentSlideIndex(feed_item.current_index);
            event.stopPropagation();
        };

        $scope.nextSlide = function (id, feed_item, files, event) {
            $('#ir-slider-'+id+' .ir-slide').removeClass('ir-slide-animation-right');
            $('#ir-slider-'+id+' .ir-slide').addClass('ir-slide-animation-left');
            feed_item.current_index = (feed_item.current_index > 0) ? --feed_item.current_index : files.length - 1;
            $scope.setCurrentSlideIndex(feed_item.current_index);
            event.stopPropagation();
        };

        //Fullscreen
        $scope.showFullscreen = function(feed_item, index, event) {
            $('body').css({
                overflow: 'hidden'
            });
            $scope.fullscreen = true;
            $scope.fullscreen_item = feed_item;
            $scope.setCurrentSlideIndex(index);
            event.stopPropagation();
        };

        $scope.showFullscreenById = function(feed_item, id, event) {
            $('body').css({
                overflow: 'hidden'
            });
            $scope.fullscreen = true;
            $scope.fullscreen_item = feed_item;
            for (var i = 0; i < feed_item.files.length; i++) {
                if (feed_item.files[i].id == id) {
                    $scope.setCurrentSlideIndex(i);
                    break;
                }
            }
            event.stopPropagation();
        };

        $scope.showFilesViewFullscreenById = function(id, files, event) {
            var feed_item = {};
            feed_item.files = files;
            feed_item.current_index = 0;
            $scope.showFullscreenById(feed_item, id, event);
            event.stopPropagation();
        };

        $scope.compressFullscreen = function() {
            $('body').css({
                overflow: 'auto'
            });
            $scope.fullscreen = false;
            $scope.fullscreen_item = {};

        };
        $scope.closeFullscreen = function(event) {
            if (!$(event.target).is("#ir-fullscreen"))
                return;
            $scope.closeEditImg();
            $scope.compressFullscreen();
        };

        $('#ir-fullscreen .ir-fullscreen-container').css({
            height: $( window ).height()
        });
        $(window).on('resize', function() {
            $('#ir-fullscreen .ir-fullscreen-container').css({
                height: $( window ).height()
            });
        });

        //edit image
        $scope.editFullscreenImage = function() {
            $scope.loading = true;
            $scope.base64Img = '';

            var index = $scope.fullscreen_item.current_index;
            var url = $scope.fullscreen_item.files[index].file_url;
            $scope.editFilename = $scope.fullscreen_item.files[index].file_name;

            return $http({
                method : "GET",
                url : laroute.route('main.getBase64UriFromImage') + '?url='+url
            }).success(function(data){
                if (data) {
                    $scope.base64Img = data;
                    $scope.editingFullscreenImg = true;
                } else {
                    $scope.editingFullscreenImg = false;
                }
                $scope.loading = false;
            });
        };

        $scope.closeEditImg = function() {
            $scope.editingFullscreenImg = false;
        };

        $scope.saveImgEdited = function() {
            if($scope.editFilename) {
                var index = $scope.fullscreen_item.current_index;
                var canvas = $('.darkroom-image-container .lower-canvas').get(0);
                var dataURL = canvas.toDataURL("image/png");
                $scope.loading = true;
                
                return $http({
                    method : "POST",
                    url : laroute.route('dashboard.update_feed_image'),
                    data: {
                        image: dataURL,
                        filename: $scope.editFilename,
                        old_file: $scope.fullscreen_item.files[index].id
                    }
                }).success(function(data){
                    $scope.loading = false;
                    success_toast('Image changes saved');
                    $scope.fullscreen_item.files[index].clean_file_url =  data.file_url;
                    $scope.fullscreen_item.files[index].file_url =  data.file_url;
                    $scope.fullscreen_item.files[index].file_name = $scope.editFilename;
                    $scope.updateFilesInfo($scope.fullscreen_item.files[index].id, $scope.fullscreen_item.files[index]);
                    $scope.editingFullscreenImg = false;
                });
            }
            else{
                error_toast('You should type a filename');
            }
        };

        $scope.updateFilesInfo = function(id, file)
        {
            if($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {
                    if(feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {
                            if (files_to_update.id == id) {
                                files_to_update.file_url = file.file_url;
                                files_to_update.clean_file_url = file.clean_file_url;
                                files_to_update.file_name = file.file_name;
                                files_to_update.filtered_comments = file.filtered_comments;
                                files_to_update.comments_count = file.comments_count;
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (files_to_update.id == id) {
                        files_to_update.file_url = file.file_url;
                        files_to_update.clean_file_url = file.clean_file_url;
                        files_to_update.file_name = file.file_name;
                        files_to_update.filtered_comments = file.filtered_comments;
                        files_to_update.comments_count = file.comments_count;
                    }
                });
            }
        };

        $scope.createComment = function(index)
        {
            if( $scope.fullscreen_item.files[index] ) {
                $scope.saving = true;
                $('.ir-comment-field').blur();
                $http({
                    method: "POST",
                    url: laroute.route('comments.store'),
                    data: {
                        text: $scope.fullscreen_item.comment_text,
                        entity_id: $scope.fullscreen_item.files[index].id,
                        entity: 'post_file'
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    $scope.fullscreen_item.comment_text = '';
                    $scope.fullscreen_item.files[index].filtered_comments = data.comments;
                    $scope.fullscreen_item.files[index].comments_count = data.comments.length;
                    $scope.updateFilesInfo($scope.fullscreen_item.files[index].id, $scope.fullscreen_item.files[index]);
                    success_toast('Comment Saved');
                }).error(function (data){
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.createReply = function(comment)
        {
            if( comment.commentable_id ) {
                $scope.saving = true;
                $('.ir-reply-field').blur();
                $http({
                    method: "POST",
                    url: laroute.route('comments.store'),
                    data: {
                        text: comment.reply_text,
                        entity_id: comment.commentable_id,
                        entity: 'post_file',
                        parent_id: comment.id
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    comment.reply_text = '';
                    comment.filtered_replies = data.comments;
                    success_toast('Reply Saved');
                }).error(function (data){
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.approveFile = function(index)
        {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.approve_file', {
                    file_id: $scope.fullscreen_item.files[index].id
                })
            }).success(function (data) {
                $scope.saving = false;
                if ($scope.fullscreen_item.post)
                    $scope.fullscreen_item.post.status = data.post.status;
                $scope.fullscreen_item.files[index].status = data.post_file.status;
                $scope.updatePostsAndFilesStatus(data.post_file.id, data.post_file.status, data.post.id, data.post.status, null);
                success_toast('File Approved');
            });
        };

        $scope.rejectFile = function(index)
        {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.reject_file', {
                    file_id: $scope.fullscreen_item.files[index].id
                })
            }).success(function (data) {
                $scope.saving = false;
                if ($scope.fullscreen_item.post)
                    $scope.fullscreen_item.post.status = data.post.status;
                $scope.fullscreen_item.files[index].status = data.post_file.status;
                $scope.updatePostsAndFilesStatus(data.post_file.id, data.post_file.status, data.post.id, data.post.status, null);
                success_toast('File Rejected');
            });
        };

        $scope.$watch('is_client', function(newVals, oldVals){
            getSettings();
        }, true);


        //Delete Feed Item
        $scope.setDeleteVal = function(index)
        {
            $scope.deleteIndex = index;
        };

        $scope.deletePost = function()
        {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('dashboard.delete_feed', {
                    feeds: $scope.feed_items[$scope.deleteIndex].id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.getPosts(false, true);
                success_toast('Post deleted');
            });
        };

        //Approve / Reject All
        $scope.setApproveVal = function(index, event)
        {
            $scope.approveIndex = index;
            event.stopPropagation();
        };
        $scope.setRejectVal = function(index, event)
        {
            $scope.rejectIndex = index;
            event.stopPropagation();
        };
        $scope.approveAll = function()
        {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.approve_all_files', {
                    posts: $scope.feed_items[$scope.approveIndex].post.id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('All Approved');
            });
        };
        $scope.rejectAll = function()
        {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.reject_all_files', {
                    posts: $scope.feed_items[$scope.rejectIndex].post.id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('All Rejected');
            });
        };

        $scope.updateFileStatus = function(id, status)
        {
            if($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {
                    if(feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {
                            if (files_to_update.id == id) {
                                files_to_update.status = status;
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (files_to_update.id == id) {
                        files_to_update.status = status;
                    }
                });
            }
        };

        $scope.updatePostsAndFilesStatus = function(id, status, post_id, post_status, files)
        {
            if($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {

                    if(post_id && post_status) {
                        if (feed_item.post && feed_item.post.id == post_id) {
                            feed_item.post.status = post_status;
                        }
                    }

                    if(feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {

                            if (id && status && files_to_update.id == id) {
                                files_to_update.status = status;
                            }

                            if (files) {
                                for (var i = 0; i < files.length; i++) {
                                    if (files[i].id == files_to_update.id) {
                                        files_to_update.status = files[i].status;
                                    }
                                }
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (id && status && files_to_update.id == id) {
                        files_to_update.status = status;
                    }

                    if (files) {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].id == files_to_update.id) {
                                files_to_update.status = files[i].status;
                            }
                        }
                    }
                });
            }
        };


        //Mobile Actions & Events
        $scope.toogleFilters = function() {
            $('#post_message').blur();
            if ($('#ir-feed-filters').hasClass('ir-filters-visible')) {
                $('#ir-feed-filters').css({
                    "overflow": "hidden"
                });
                $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
            } else {
                $('#ir-feed-filters, #ir-new-post-container').addClass('ir-filters-visible');
                $('#ir-feed-filters').one(transitionEvent, function () {
                    $('#ir-feed-filters').css({
                        "overflow": "visible"
                    });
                });
            }
            $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
            $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
            $('#ir-check-box').addClass('no-visible');
        };
        $scope.showPostBox = function(type) {
            if (type == 'message') {
                if($('#ir-new-post-message').hasClass('ir-new-post-message-visible')) {
                    $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
                    $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
                } else {
                    $('#ir-new-post-message').addClass('ir-new-post-message-visible');
                    $('#ir-new-post-container .ir-new-post-box').addClass('ir-new-post-box-visible');
                }
            } else {
                if ($scope.post)
                    $scope.post.message = '';
                $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
                $('#ir-new-post-container .ir-new-post-box').addClass('ir-new-post-box-visible');
            }
            $('#ir-feed-filters').css({
                "overflow": "hidden"
            });
            $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
            $('#ir-check-box').addClass('no-visible');

            $scope.setNewPostLabel();
        };

        $scope.getObjects = function (type) {
            if(!$scope.is_checked){
                var client = $scope.checkInClient ? $scope.checkInClient.id : '';
                var project = $scope.checkInProject ? $scope.checkInProject.id : '';

                return $http({
                    method: "GET",
                    url: laroute.route('dashboard.get_objects', {
                        client_id: client,
                        project_id: project
                    })
                }).success(function (data) {
                    if (type == 'clients') {
                        $scope.checkInClients = data.clients;
                        $scope.checkInProjects = [];
                        $scope.checkInStores = [];

                        $scope.checkInClients.unshift({name: 'Select a client', value: ''});
                        $scope.checkInProjects.unshift({name: 'Select a project', value: ''});
                        $scope.checkInStores.unshift({name: 'Select an store', value: ''});

                        $scope.checkInClient = $scope.checkInClients[0];
                        $scope.checkInProject = $scope.checkInProjects[0];
                        $scope.checkInStore = $scope.checkInStores[0];
                    }

                    if (type == 'projects') {
                        $scope.checkInStores = [];
                        $scope.checkInProjects = data.projects;

                        $scope.checkInProjects.unshift({name: 'Select a project', value: ''});
                        $scope.checkInStores.unshift({name: 'Select an store', value: ''});

                        $scope.checkInProject = $scope.checkInProjects[0];
                        $scope.checkInStore = $scope.checkInStores[0];
                    }

                    if (type == 'stores') {
                        $scope.checkInStores = data.stores;

                        $scope.checkInStores.unshift({name: 'Select an store', value: ''});

                        $scope.checkInStore = $scope.checkInStores[0];
                    }
                });
            }
        };


        $scope.showCheckBox = function() {
            $scope.recently_checked = false;
            if(!$('#ir-check-box').hasClass('no-visible')){
                $('#ir-check-box').addClass('no-visible')
            }
            else{
                $scope.getObjects('clients');
                $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
                $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
                $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
                $('#ir-feed-filters').css({
                    "overflow": "hidden"
                });

                $('#ir-comment-alert').addClass('no-visible');
                $('#ir-photo-alert').addClass('no-visible');

                $('#ir-check-box').removeClass('no-visible');
            }
        };

        $scope.checkInOut = function (action) {
            if($scope.checkInStore.id){
               $scope.saving = true;
                return $http({
                    method: "POST",
                    url: laroute.route('checks.store'),
                    data: {
                        client_id: $scope.checkInClient.id,
                        project_id: $scope.checkInProject.id,
                        store_id: $scope.checkInStore.id,
                        type: action,
                        comment: $scope.check_out_comment
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    $scope.is_checked = action == 'in';
                    $scope.check_out_comment = null;
                    if(action == 'out'){
                        $scope.getObjects('clients');
                    }
                    else{
                        $scope.recently_checked = true
                    }
                    $scope.getPosts(false, true);
                }).error(function (data){
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.showCheckInError = function (type) {
            $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
            $('#ir-feed-filters').css({
                "overflow": "hidden"
            });
            $('#ir-check-box').addClass('no-visible');

            if(type == 'photo'){
                $('#ir-photo-alert').removeClass('no-visible');
                $('#ir-comment-alert').addClass('no-visible');
            } else {
                $('#ir-comment-alert').removeClass('no-visible');
                $('#ir-photo-alert').addClass('no-visible');
            }
        }

        $scope.$on('expandSearch', function (event) {
            if(md.mobile() && $(window).width() <= 600) {
                $('.nav-left').css('position', 'absolute');
                $('.nav-left').removeClass('s4').addClass('s12');
                $('.search-input').removeClass('hide-on-small-and-down');
            }
            $('.search-input').focus();
        });

        $scope.hideSearch = function(){
            $('#closeSearch').remove();
            if(md.mobile()) {
                $('.nav-left').addClass('s4').removeClass('s12');
                $('.nav-left').css('position', 'relative');
                $('.search-input').addClass('hide-on-small-and-down');
            }
        };

        $scope.searchInputChanged = function (str) {
            var method = "'cleanSearch'",
                html = '<i id="closeSearch" class="material-icons" ng-mousedown="$emit(' + method +')">close</i>';
            if(!$('#searchInput').find('#closeSearch').length){
                var temp = $compile(html)($scope);
                angular.element(document.getElementById('searchInput')).append(temp);
            }
        };

        $scope.searchFocus = function () {
            if($('#search_value').val()){
                $scope.searchInputChanged();
            }
        }
    }])

    .filter('highlight', function($sce) {
        return function(text, phrase) {
            if (phrase) text = text.replace(new RegExp('('+phrase+')', 'gi'),
                '<span class="highlightedText">$1</span>');

            return $sce.trustAsHtml(text)
        }
    })


    .animation('.ir-slide-animation-right', function () {
        return {
            addClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    TweenMax.to(element, 0.5, {left: -element.parent().width(), onComplete: done });
                }
                else {
                    done();
                }
            },
            removeClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    element.removeClass('ng-hide');

                    TweenMax.set(element, { left: element.parent().width() });
                    TweenMax.to(element, 0.5, {left: 0, onComplete: done });
                }
                else {
                    done();
                }
            }
        };
    })
    .animation('.ir-slide-animation-left', function () {
        return {
            addClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    TweenMax.to(element, 0.5, {left: element.parent().width(), onComplete: done });
                }
                else {
                    done();
                }
            },
            removeClass: function (element, className, done) {
                if (className == 'ng-hide') {
                    element.removeClass('ng-hide');

                    TweenMax.set(element, { left: -element.parent().width() });
                    TweenMax.to(element, 0.5, {left: 0, onComplete: done });
                }
                else {
                    done();
                }
            }
        };
    })