angular.module('ireactApp.profile', ['ireactApp.controllers'])

	/**
     * User Profile
     */
    .controller('UserProfileController', ['$scope', '$http', function ($scope, $http) {
        $scope.saveProfile = function () {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('profile.update'),
                data: {
                    user: $scope.user
                }
            }).success(function (data) {
                $scope.saving = false;
                success_toast('Profile Saved');
            }).error(function (data) {
                $scope.saving = false;
                error_toast(data.errors);
            });
        };


        $scope.changePassword = function () {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('profile.change_password'),
                data: {
                    old_password: $scope.user.old_password,
                    new_password: $scope.user.new_password,
                    password_confirmation: $scope.user.password_confirmation
                }
            }).success(function (data) {
                $scope.resetChangePasswordForm();
                $scope.saving = false;
                success_toast('Password Changed');
            }).error(function (data) {
                $scope.saving = false;
                error_toast(data.errors);
            });
        };

        $scope.resetChangePasswordForm = function () {
            $scope.frmChangePassword.$setPristine(true);
            $scope.user.old_password = null;
            $scope.user.new_password = null;
            $scope.user.password_confirmation = null;
        };

        $scope.$watch('change_password', function(newVal, oldVal){
            if(newVal == false){
                $scope.resetChangePasswordForm();
            }
        }, true);

      $scope.$watch('user', function(newVal, oldVal){
        if ($scope.user.eu_date_format == 1) {
          $scope.user.eu_date_format = true;
        }else{
          $scope.user.eu_date_format = false;
        }
      }, true);

    }]);