angular.module('ireactApp.stores', ['ireactApp.controllers', 'ngMaterial','ngCookies'])

    /**
     * Store List Page - Project Show
     */
    .controller('StoreListController', ['$scope', '$http', 'Upload', '$timeout', '$mdDialog', '$filter', 'orderByFilter','$cookies' , function ($scope, $http, Upload, $timeout, $mdDialog, $filter, orderBy,$cookies) {
        $scope.date_format = date_format; //global variable at master layout
        $scope.stores = [];
        $scope.loading = true;
        $scope.project_id = null;
        $scope.columnsNumber = 0;
        $scope.storesSetted = false;
        $scope.order_by = 'name';
        $scope.order_dir = false;
        
        $scope.edited = false; // used for edit checks

        $scope.tableColumns = {
            name: false,
            number: false,
            mall_name: false,
            address: false,
            city: false,
            state: false,
            country: false,
            survey_upload: true,
            install_check_in: true,
            install_check_out: true,
            revisit_needed: true,
            notes: true
        };

        //pager
        $scope.pager_current = 1;
        $scope.pager_items = 0;
        $scope.pager_start = 0;
        $scope.pager_end = 0;
        $scope.pager_pages = 1;
        $scope.pager_show = 25;

        //filter dates to download project's files zipped
        $scope.dateFrom = null;
        $scope.dateTo = null;
        $scope.datepickerLocale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(date_format) : '';
            }
        };

        $scope.redirectStore = function(id)
        {
            saveSettings();
            window.location = laroute.route('stores.index')+'/'+id+'/'+$scope.project_id;
        };

        $scope.paginate = function(page)
        {
            $scope.pager_current = page;
            $scope.pager_end = $scope.pager_show * page;
            $scope.pager_start = $scope.pager_end - $scope.pager_show;
        };

        $scope.switchPage = function(index)
        {
            $scope.pager_current = index;
            $scope.paginate(index);
        };

        $scope.prevPage = function()
        {
            if ($scope.pager_current !== 1) {
                $scope.pager_current = $scope.pager_current - 1;
                $scope.paginate($scope.pager_current);
            }
        };

        $scope.nextPage = function()
        {
            if ($scope.pager_current !== $scope.pager_pages) {
                $scope.pager_current = $scope.pager_current + 1;
                $scope.paginate($scope.pager_current);
            }
        };

        $scope.orderBy = function(order_by)
        {
            $scope.order_dir = (order_by !== null && $scope.order_by === order_by)
                ? !$scope.order_dir : false;
            $scope.filtered_stores = orderBy($scope.filtered_stores, order_by, $scope.order_dir);
            $scope.order_by = order_by;

            //Save sorting settings for [each] project in cookie so that we can restore when they visit back from another page.
            $cookies.put("order_by",order_by,{path:"/projects/" + $scope.project_id});
            $cookies.put("order_dir",$scope.order_dir?1:0,{path:"/projects/" + $scope.project_id});
        };

        $scope.filterStores = function(store)
        {
            var term = $scope.searchText ? $scope.searchText.toLowerCase() : '';
            var storeName = store.name ? store.name.toLowerCase().indexOf(term) > -1 : false;
            var storeNumber = store.number ? store.number.toLowerCase().indexOf(term) > -1 : false;
            var storeAddress = store.address ? store.address.toLowerCase().indexOf(term) > -1 : false;
            var storeMallName = store.mall_name ? store.mall_name.toLowerCase().indexOf(term) > -1 : false;
            var storeCity = store.city ? store.city.toLowerCase().indexOf(term) > -1 : false;
            var storeState = store.state ? store.state.toLowerCase().indexOf(term) > -1 : false;
            var storeCountry = store.country ? store.country.toLowerCase().indexOf(term) > -1 : false;
            var storeNotes = store.notes ? store.notes.toLowerCase().indexOf(term) > -1 : false;

            var storeSurveyUpload = false;
            if (store.survey_upload) {
                var survey_date = moment(store.survey_upload);
                var survey_upload_string = survey_date.format('MM/DD/YYYY');
                storeSurveyUpload = survey_upload_string.indexOf(term) > -1;
            }

            var storeInstallDate = false;
            if (store.installation_date) {
                var installation_date = moment(store.installation_date);
                var installation_date_string = installation_date.format('MM/DD/YYYY');
                storeInstallDate = installation_date_string.indexOf(term) > -1;
            }

            return storeName ||
                storeNumber ||
                storeAddress ||
                storeMallName ||
                storeCity ||
                storeState ||
                storeCountry ||
                storeSurveyUpload ||
                storeInstallDate ||
                storeNotes;
        };

        $scope.updatePagination = function()
        {
            var items = $filter('filter')($scope.stores, $scope.filterStores, $scope.searchText);
            $scope.filtered_stores = angular.copy(items);
            $scope.pager_items = items.length;
            $scope.pager_pages = Math.ceil($scope.pager_items / $scope.pager_show);
            $scope.paginate(1);
        };

        $scope.hideColumn = function(column, value)
        {
            $scope.tableColumns[column] = value;
            saveSettings();
        };

        $scope.openMenu = function($mdOpenMenu, ev) {
            originatorEv = ev;
            $mdOpenMenu(ev);
        };

        function saveSettings() {
            $http({
                method : "POST",
                url : laroute.route('dashboard.store_settings'),
                data: {
                    hidden_columns: JSON.stringify($scope.tableColumns),
                    order: 'asc',
                    view: $scope.active_view,
                    client: $scope.client_id,
                    project: $scope.project_id,
                    store: $scope.store_id,
                    tag: null
                }
            });
        }

        $scope.hasHiddenRows = function()
        {
            var display = true;
            var count = 0;

            for (var key in $scope.tableColumns) {
                if (!$scope.tableColumns.hasOwnProperty(key)) continue;
                var obj = $scope.tableColumns[key];

                if(obj == true){
                    display = false;
                    count++;
                }
            }

            $scope.columnsNumber = count;

            return display;
        };

        $scope.is_container = function(){
            if (md.mobile.length == 0){
                if ($('body').width() <= 1200 && $scope.columnsNumber >= 6) {
                    return true;
                }
                if ($('body').width() > 1200 && $scope.columnsNumber >= 4) {
                    return true;
                }
            }
            return false;
        };

        //Set stores from filter controller
        $scope.$on('setStores', function(e, stores) {
            $scope.storesSetted = true;
            $scope.stores = stores;
            $scope.pager_items = stores.length;
            $scope.pager_pages = Math.ceil($scope.pager_items / $scope.pager_show);
            $scope.paginate($scope.pager_current);
            $scope.loading = false;
            $scope.filtered_stores = angular.copy(stores);

            if($scope.filtered_stores)    
            $scope.filtered_stores = $scope.filtered_stores.map(item=>{
                item.number = parseInt(item.number);
                return item;
            });
            
            $scope.orderBy($scope.order_by);
        });

        $scope.$on('setSettings', function(e, data) {
            var tables = JSON.parse(data.setting.hidden_columns);

            if (tables instanceof Array){
                //do nothing
            }else if (tables instanceof Object) {
                $scope.tableColumns = tables;
            }

            var sort = data.setting.order.split(' ');
            $scope.sort = sort[0];
            $scope.sort_dir = sort[1];
            $scope.active_view = data.setting.view;
        });

        //Edit Install Check In/Out and Revisist Needed checks
        $scope.checkChanged = function()
        {
          $scope.edited = true;
        };

        $scope.cancelEditChecks = function()
        {
            $scope.updatePagination();
            $scope.edited = false;
        };

        $scope.saveChecks = function()
        {
            $scope.loading = true;
            $http({
                method: "PUT",
                url: laroute.route('stores.save_checks'),
                data: {
                    stores: $scope.filtered_stores,
                    project_id: $scope.project_id
                }
            }).success(function (data) {
                $scope.loading = false;
                $scope.edited = false;
                success_toast('Stores Updated');
            });
        };


        // Download Project Files zipped
        $scope.showPrerenderedFilterProjectZippedFilesDialog = function(ev) {
            $scope.dateFrom = null;
            $scope.dateTo = null;
            $mdDialog.show({
                controller: DialogProjectZippedFilesController,
                contentElement: '#downloadProjectZippedFilesDialog',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            });
            $scope.mdDialog = $mdDialog;
        };

        $scope.downloadProjectZippedFiles = function() 
        {
            $scope.loading = true;
            $scope.mdDialog.hide();

            // Set DateFrom Format
            var dateFrom_string = null;
            if($scope.dateFrom && moment($scope.dateFrom, date_format).isValid()) {
                var dateFrom = moment($scope.dateFrom);
                dateFrom_string = dateFrom.format(date_format);
            }
            var dateTo_string = null;
            if($scope.dateTo && moment($scope.dateTo, date_format).isValid()) {
                var dateTo = moment($scope.dateTo);
                dateTo_string = dateTo.format(date_format);
            }

            // Send Export Project Files
            $http({
                method: "GET",
                url: laroute.route('projects.export_project_files',{
                    project_id: $scope.project_id,
                    dateFrom: dateFrom_string,
                    dateTo: dateTo_string
                })
            }).success(function (data) {
                $scope.loading = false;
                success_toast('Zip file is being generated. Please check your mail to get the download link');
            }).error(function (data) {
                $scope.loading = false;
                error_toast('Error creating Zip job');
            });            
        };

        function DialogProjectZippedFilesController($scope, $mdDialog) {

            $scope.hide = function() {
                $mdDialog.hide();
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };

            $scope.answer = function(answer) {
                $mdDialog.hide(answer);
            };
                
        }

        $scope.init=function() {
            //Restore sort settings for selected project

            if($cookies.get("order_by")!=null && $cookies.get("order_by")!=null)
            {
                $scope.order_by = $cookies.get("order_by")!=null?$cookies.get("order_by"):'name';
                $scope.order_dir = $cookies.get("order_dir")==1?false:true;
                $scope.orderBy($scope.order_by);
            }
        };
        $scope.init();
    }])

    /**
     * Stores Index and Project Show Store List
     */
    .controller('StoresIndexController', ['$scope', '$http', 'Upload', '$timeout', '$mdDialog' , function ($scope, $http, Upload, $timeout, $mdDialog) {

        $scope.stores = [];
        $scope.loading = true;
        $scope.saving = false;
        $scope.order_by = '';
        $scope.order_dir = 'asc';
        $scope.more_stores = 5;
        $scope.previous_stores = false;
        $scope.temp_stores = [];
        $scope.previous_all = false;
        $scope.contractors = [];
        $scope.projects = [];
        $scope.project_id = null;
        $scope.stores_project_id = null;
        $scope.columnsNumber = 0;
        $scope.stores_ids = [];
        $scope.adding_stores = false;
        $scope.storesSetted = false;

        $('#frmManageStores').validate({
            errorPlacement: function(error, element) {
                if (element.parent("div.input-group").length > 0)
                    error.appendTo( element.parent("div.input-group").parent('td') );
                else
                    error.appendTo( element.parent('td') );
            },
            errorElement: 'li',
            wrapper: 'ul'
        });

        $scope.hideLoading = function() {
            $scope.loading = false;
            $scope.saving = false;
        };

        $scope.datepickerLocale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(date_format) : '';
            }
        };

        $scope.querySearch = function(query){

            if($scope.adding_stores){
                return [];
            }else{
                if ($scope.search_stores) {
                    var results = $scope.search_stores.filter(createFilterFor(''));
                    if (query) {
                        results = $scope.search_stores.filter(createFilterFor(query));
                        results.unshift({name: query});
                    }
                    return results;
                } else {
                    return [];
                }
            }
        };

        $scope.selectedItemChange = function(item, index){
            if(typeof(item) != 'undefined'){
                if(item.id) {
                    $scope.stores[index].id = item.id;
                    $scope.stores[index].number = item.number;
                    $scope.stores[index].address = item.address;
                    $scope.stores[index].mall_name = item.mall_name;
                    $scope.stores[index].city = item.city;
                    $scope.stores[index].state = item.state;
                    $scope.stores[index].country = item.country;
                    $scope.stores[index].phone = item.phone;
                }
                $scope.stores[index].name = item.name;
            }
            updateStoreIds();
        };

        $scope.newStore = function(store, index){
            var stores = $scope.search_stores;
            stores.push(
                {
                    name: store,
                    name_lower: store.toLowerCase()
                }
            );
            $scope.search_stores = stores;
            $scope.searchStoreName = null;
            $scope.stores[index] = $scope.search_stores[$scope.search_stores.length-1];
            $scope.stores[index].selectedStore = angular.copy($scope.search_stores[$scope.search_stores.length-1]);

        };

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(store) {

                if($scope.stores_ids.indexOf(store.id) < 0){
                    return (store.name_lower.indexOf(lowercaseQuery) === 0);
                }
            };
        }


        function updateStoreIds(){
            $scope.stores_ids = [];
            for (var i = 0; i < $scope.stores.length; i++) {
                var store = $scope.stores[i];
                $scope.stores_ids.push(store.id);
            }
        }

        $scope.getStores = function()
        {
            $http({
                method : "GET",
                url : laroute.route('stores.get_data', {
                    order_by: $scope.order_by,
                    order_dir: $scope.order_dir,
                    client_id: $scope.client_id,
                    project_id: $scope.project_id ? $scope.project_id : ''
                })
            }).success(function(data){
                $scope.getContractors(data.stores);
                $scope.pager_items = data.stores.length;
                $scope.pager_pages = Math.ceil($scope.pager_items / $scope.pager_show);
            });

            getSearchStores();
        };

        function getSearchStores() {
            $http({
                method : "GET",
                url : laroute.route('stores.get_data', {
                    order_by: 'name',
                    order_dir: 'asc',
                    client_id: $scope.client_id
                })
            }).success(function(data){
                var reformattedArray = data.stores.map(function(obj){
                    obj['name_lower'] = obj.name.toLowerCase();
                    return obj;
                });
                $scope.search_stores = reformattedArray;
            });
        }

        $scope.getProjects = function()
        {
            $scope.projects = [];

            $http({
                method : "GET",
                url : laroute.route('projects.get_data', {
                    client_id: $scope.client_id,
                    order_by: 'name',
                    order_dir: 'asc'
                })
            }).success(function(data){
                $scope.projects = data.projects;
            });
        };

        $scope.getClientStores = function()
        {
            $scope.previous_stores = false;
            $scope.previous_all = false;
            $scope.temp_stores = [];
            $scope.clientStores = [];
            $http({
                method : "GET",
                url : laroute.route('stores.get_data', {
                    order_by: 'name',
                    client_id: $scope.client_id,
                    project_id: $scope.stores_project_id
                })
            }).success(function(data){
                $scope.loading = false;
                $scope.previous_stores = true;
                $scope.clientStores = data.stores;
            });
        };

        $scope.getContractors = function(stores)
        {
            $http({
                method : "GET",
                url : laroute.route('users.get_contractors', {
                    client_id: $scope.client_id
                })
            }).success(function(data){

                var contractors = [];
                angular.forEach(data.users, function (contractor, key) {
                    contractors.push($scope.castContractor(contractor));
                });

                angular.forEach(stores, function (store, key) {
                    store.installation_date = store.installation_date ? new Date(store.installation_date.split("-").join("/")) : null;
                    store.selectedStore = angular.copy(store);
                });

                $scope.contractors = contractors;
                $scope.stores = stores;
                updateStoreIds();

                if (stores.length == 0) {
                    $scope.pushStore();
                }
            });
        };

        $scope.orderBy = function(order_by)
        {
            if (order_by == $scope.order_by) {
                $scope.order_dir = $scope.order_dir == 'asc' ? 'desc' : 'asc';
            } else {
                $scope.order_dir = 'asc';
            }
            $scope.order_by = order_by;
            $scope.getStores();
        };

        $scope.pushStore = function()
        {
            $scope.stores.push({
                id: null,
                number: '',
                name: '',
                address: '',
                mall_name: '',
                city: '',
                state: '',
                country: '',
                phone: '',
                required: false,
                contractor: null,
                installation_date: null
            });
        };

        function clearNulls(){
            var i = 0;
            $scope.stores.forEach(function(item){
                if(typeof(item.name) == 'object'){
                    $('#autocomplete-'+i).find( "button" ).click();
                    $('body').click();
                }
                i++;
            });
            $scope.adding_stores = false;
        }

        $scope.addStoreRow = function(index)
        {
            if (index == $scope.stores.length -1) {
                $scope.pushStore();
            }
        };

        $scope.addStores = function()
        {
            $scope.adding_stores = true;
            if (parseInt($scope.more_stores) > 0) {
                for (var i = 0; i < $scope.more_stores; i++) {
                    $scope.pushStore();
                }
            }
            $scope.adding_stores = false;
        };

        $scope.removeStoreRow = function(index)
        {
            if ($scope.stores[index].posts_count && $scope.stores[index].posts_count > 0) {
                var confirm = $mdDialog.confirm()
                    .title('Remove Store from Project')
                    .textContent('Data exists in Store, are you sure you want to delete it? You will not be able to retrieve it.')
                    .ok('Remove')
                    .cancel('Cancel');

                $mdDialog.show(confirm).then(function() {
                    $scope.stores.splice(index, 1);
                    updateStoreIds();
                }, function() {});
            } else {
                $scope.stores.splice(index, 1);
                updateStoreIds();
            }
        };

        $scope.saveStores = function() {
            //Remove empty stores
            var temp = [];
            var reload = false;
            angular.forEach($scope.stores, function (store, key) {
                if(store.id || store.name){
                    temp.push(store);
                }else{
                    reload = true;
                }
            });

            $scope.stores = temp;

            if(reload){
                setTimeout(function(){$scope.saveStores()},200);
            }

            if ($('#frmManageStores').valid()) {
                $scope.saving = true;
                $http({
                    method: "POST",
                    url: laroute.route('stores.store'),
                    data: {
                        stores: $scope.stores,
                        order_by: $scope.order_by,
                        order_dir: $scope.order_dir,
                        client_id: $scope.client_id,
                        project_id: $scope.project_id ? $scope.project_id : ''
                    }
                }).success(function (data) {
                    angular.forEach(data.stores, function (store, key) {
                        store.installation_date = store.installation_date ? new Date(store.installation_date.split("-").join("/")) : null;
                        store.selectedStore = angular.copy(store);
                    });

                    $scope.stores = data.stores;
                    updateStoreIds();
                    $scope.frmManageStores.submitted = false;
                    $scope.frmManageStores.$setPristine();
                    success_toast('Stores saved');
                    window.location = laroute.route('projects.show', {projects: $scope.project_id});
                }).error(function (data){
                    angular.forEach(data.stores, function (store, key) {
                        store.installation_date = store.installation_date ? new Date(store.installation_date.split("-").join("/")) : null;
                        store.selectedStore = angular.copy(store);
                    });

                    $scope.stores = data.stores;
                    updateStoreIds();
                    getSearchStores();
                    $scope.saving = false;
                    angular.forEach(data.errors, function (errors, key) {
                        error_toast(errors.errors);
                    });
                });
            } else {
                $scope.frmManageStores.submitted = true;
            }

        };

        $scope.setDeleteVal = function(index){
            $scope.index = index;
        };

        $scope.deleteStore = function()
        {
            $http({
                method: "DELETE",
                url: laroute.route('stores.destroy', {
                    stores: $scope.stores[$scope.index].id,
                    order_by: $scope.order_by,
                    order_dir: $scope.order_dir,
                    client_id: $scope.client_id,
                    project_id: $scope.project_id ? $scope.project_id : ''
                })
            }).success(function (data) {
                $scope.stores = data.stores;
                $scope.saving = false;
                success_toast('Store deleted');
            });
        };

        $scope.uploadFileStore = function(file){
            $scope.saving = true;
            Upload.upload({
                url:  laroute.route('projects.process_stores_file'),
                method: "POST",
                data: {
                    client_id: $scope.client_id,
                    project_id: $scope.project_id ? $scope.project_id : '',                    
                    file: file,
                    client: $scope.client_id
                }
            }).success(function (data, status, headers, config) {
                if(data.errors.length) 
                {
                    error_toast(data.errors);
                }
                if((data.stores.length == 0) && (data.errors.length == 0)) 
                {
                    error_toast('Empty file could not be imported');
                }
                else 
                {
                    if($scope.stores.length == 1 && !$scope.stores[0].number && !$scope.stores[0].name
                        && !$scope.stores[0].address && !$scope.stores[0].city && !$scope.stores[0].phone) {
                        $scope.stores.splice(0, 1);
                    }
                    var updated = 0;
                    angular.forEach(data.stores, function($elem) {
                        if(typeof($elem.id) != 'undefined' && $scope.stores_ids.indexOf($elem.id) >= 0)
                        {
                            var i = 0;
                            $scope.stores.forEach(function(store){
                                if(store.id == $elem.id){

                                    $scope.stores[i] = angular.copy($elem);
                                    $scope.stores[i].selectedStore = angular.copy($elem);
                                    $scope.stores[i].contractor = store.contractor;
                                    $scope.stores[i].client_id = store.client_id;
                                    $scope.stores[i].install_check_in = store.install_check_in;

                                    if ($elem.installation_date && $elem.installation_date.date) {
                                        $scope.stores[i].installation_date = $elem.installation_date.date;
                                    } else {
                                        $scope.stores[i].installation_date = store.installation_date;
                                    }

                                    $scope.stores[i].install_check_out = store.install_check_out;
                                    $scope.stores[i].notes = store.notes;
                                    $scope.stores[i].revisit_needed = store.revisit_needed;
                                    $scope.stores[i].survey_upload = store.survey_upload;
                                    $scope.stores[i].zip = store.zip;

                                    updated++;
                                }
                                i++;
                            });
                        }
                        else if(typeof($elem.id) != 'undefined' && $scope.stores_ids.indexOf($elem.id) == -1)
                        {
                            // Autosaved elements, new element in frontend with ID
                            var new_store_autosaved = angular.copy($elem);
                            new_store_autosaved.selectedStore = angular.copy($elem);      
                            new_store_autosaved.client_id = $elem.client_id;
                            if (typeof($elem.contractor) != 'undefined')           
                            {
                                new_store_autosaved.contractor = $elem.contractor;                            
                            }           

                            if ($elem.installation_date && $elem.installation_date.date) {
                                new_store_autosaved.installation_date = $elem.installation_date.date;
                            } else {
                                new_store_autosaved.installation_date = null;
                            }
                            
                            $scope.stores.push(new_store_autosaved);
                        }
                        else
                        {
                            var new_store = angular.copy($elem);
                            if ($elem.installation_date && $elem.installation_date.date) {
                                new_store.installation_date = $elem.installation_date.date;
                            } else {
                                new_store.installation_date = null;
                            }
                            new_store.selectedStore = angular.copy($elem);
                            $scope.stores.push(new_store);
                        }
                    });
                    if((data.stores.length-updated)>0){
                        success_toast((data.stores.length-updated) + ' Rows imported');
                    }
                    if (updated>0){
                        success_toast(updated + ' Rows updated');
                    }
                }

                $scope.saving = false;
                $scope.project.file = null;
                updateStoreIds();

            }).error(function (data){
                $scope.saving = false;
                angular.forEach(data.errors, function (errors, key) {
                    error_toast(errors.errors);
                });
            });
        };

        $scope.selectStore = function(event, index)
        {
            if($scope.clientStores[index].selected){
                $scope.temp_stores[index] = $scope.clientStores[index];
            } else {
                delete $scope.temp_stores[index];
            }
        };

        $scope.addPreviousStores = function()
        {
            $scope.loading = true;
            updateStoreIds();

            var current = 0;
            var added = 0;

            angular.forEach($scope.temp_stores, function($elem){

                if($scope.stores_ids.indexOf($elem.id)>=0){
                    current++;
                }else{
                    var new_store = angular.copy($elem);
                    new_store.selectedStore = angular.copy($elem);
                    new_store.contractor = null;
                    new_store.installation_date = null;
                    new_store.install_check_in = null;
                    new_store.install_check_out = null;
                    new_store.revisit_needed = null;
                    new_store.survey_upload = null;
                    new_store.notes = null;
                    new_store.selectedStore = angular.copy($elem);
                    $scope.stores.push(new_store);
                    added++;
                }
            });

            $mdDialog.hide();
            $scope.previous_stores = false;
            $scope.temp_stores = [];

            if(current>0){
                success_toast(current + ' Elements already in the list');
            }
            if (added>0){
                success_toast(added + ' Rows added');
            }
            $scope.loading = false;
        };

        $scope.selectAll = function()
        {
            if ($scope.previous_all) {
                angular.forEach($scope.clientStores, function(value, key) {
                    $scope.clientStores[key].selected = true;
                    $scope.temp_stores[key] = $scope.clientStores[key];
                });

            }else{
                $scope.temp_stores = [];
                angular.forEach($scope.clientStores, function(value, key) {
                    $scope.clientStores[key].selected = false;
                });
            }
        };

        $scope.getDate = function(id)
        {
            if (typeof($scope.stores[id].installation_date) != 'undefined'
                && $scope.stores[id].installation_date !== null && !$scope.stores[id].installation_date instanceof Date) {
                date = $scope.stores[id].installation_date.split("-").join("/");
                $scope.stores[id].installation_date = new Date(date);
            }
        };

        $scope.castContractor = function(contractor)
        {
          return { id: contractor.id, name: contractor.name };
        };

        $scope.showPrerenderedDialog = function(ev) {
            $scope.getProjects();
            $scope.getClientStores();
            $mdDialog.show({
                controller: DialogController,
                contentElement: '#storesDialog',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            });
            $scope.mdDialog = $mdDialog;
        };

        function DialogController($scope, $mdDialog) {

            $scope.hide = function() {
                $mdDialog.hide();
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };

            $scope.answer = function(answer) {
                $mdDialog.hide(answer);
            };
        }

        $scope.getBoolean = function(index, type)
        {
            if ($scope.stores[index][type] == 1){
                $scope.stores[index][type] = true;
            }else{
                $scope.stores[index][type] = false;
            }
        };

        $scope.$watch('project_id', function(newVals, oldVals){
            if ($scope.project_id) {
                $scope.getStores();
            }
        }, true);

    }])

    .filter('capitalize', function() {
        return function(input) {
            return (!!input) ? input.split('_').map(function(wrd){return wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase();}).join(' ') : '';
        }
    })

    .controller('NewFeedController', ['$scope', '$http', 'Upload', '$filter', '$timeout', function ($scope, $http, Upload, $filter, $timeout) {
        $scope.date_format = date_format; //global variable at master layout
        $scope.feeds = [];
        $scope.project_id = 0;
        $scope.client_id = 0;
        $scope.store_id = 0;
        $scope.tag_id = 0;
        $scope.file_icons = file_icons;

        $scope.project = {};
        $scope.client = {};
        $scope.store = {};
        $scope.loading = true;
        $scope.editing = true;
        $scope.new_post_label = 'Comments...';
        $scope.post = {
            message: "",
            files: [],
            custom_tag: "",
            tag_id: null,
            tag_scope: 'only_store' // default creates tag for this store only
        };

        $scope.active_view = 'list';
        $scope.feed_items_table = [];
        $scope.feed_items = [];

        $scope.fullscreen_item = {};

        $scope.datepickerLocale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(date_format) : '';
            }
        };

        function setCurrentUser(){
            if ($scope.client_id) {
                $scope.client = _.find($scope.clients, function(c){ return c.id == $scope.client_id });
                setCurrentProject();
            }
        }

        function setCurrentProject(){
            if ($scope.project_id) {
                $scope.project = _.find($scope.projects, function(c){ return c.id == $scope.project_id });
                setCurrentStore();
            }
        }

        function saveSettings() {
            $http({
                method : "POST",
                url : laroute.route('dashboard.store_settings'),
                data: {
                    hidden_columns: JSON.stringify($scope.tableColumns),
                    order: 'asc',
                    view: $scope.active_view,
                    client: $scope.client_id,
                    project: $scope.project_id,
                    store: $scope.store_id,
                    tag: null
                }
            });
        }

        function setCurrentStore(){

            if ($scope.store_id) {
                $scope.store = _.find($scope.stores, function(c){
                    return c.id == $scope.store_id });

                //$scope.new_post_label = 'Post to ' + $scope.store.name + ' store...';

                if ($scope.store.survey_upload) {
                    var new_date = $scope.store.survey_upload.split("-").join("/");
                    $scope.store.survey_upload = new Date(new_date);
                }
                if ($scope.store.installation_date) {
                    var new_date = $scope.store.installation_date.split("-").join("/");
                    $scope.store.installation_date = new Date(new_date);
                }
                if ($scope.store.last_updated_at) {
                    var new_date = $scope.store.last_updated_at.split("-").join("/");
                    $scope.store.last_updated_at = new Date(new_date);
                }
                

                //check-in-out
                if ($scope.store.install_check_in == 1) {
                    $scope.store.install_check_in = true;
                }else{
                    $scope.store.install_check_in = false;
                }
                if ($scope.store.install_check_out == 1) {
                    $scope.store.install_check_out = true;
                }else{
                    $scope.store.install_check_out = false;
                }
                if ($scope.store.revisit_needed == 1) {
                    $scope.store.revisit_needed = true;
                }else{
                    $scope.store.revisit_needed = false;
                }
            }
        }

        $scope.openMenu = function($mdOpenMenu, ev) {
            originatorEv = ev;
            $mdOpenMenu(ev);
        };

        $scope.getFeeds = function ()
        {
            $scope.loading = true;
            return $http({
                method : "GET",
                url : laroute.route('dashboard.get_feed', {
                    client_id: $scope.client_id,
                    project_id: $scope.project_id,
                    store_id: $scope.store_id,
                    tag_id: $scope.tag_id,
                    page: 1,
                    table_page:1,
                    view: 'list',
                    sort: 'feed_items.created_at',
                    sort_dir: 'desc'
                })
            }).success(function(data){
                $scope.feed_items = data.feed_items;
                $scope.feed_items_table = data.feed_items_table;
                $scope.files = data.files;
                $scope.clients = data.clients;
                $scope.projects = data.projects;
                $scope.stores = data.stores;

                var manage = null;
                if(!md.mobile() && $scope.is_admin){
                    data.tags.unshift({value: "manage", name:"Manage Folders..."});
                    manage = {value: "manage", name:"Manage Folders..."};
                }

                if($scope.store_id != 0){
                    $scope.tags = data.tags;
                    $scope.$parent.tags = data.tags;
                }else{
                    $scope.tags = [];
                    $scope.$parent.tags = [manage];
                }
                $scope.loading = false;

                setCurrentUser();

            });
        };

        function saveStore(edit){

            if(typeof(edit) == 'undefined'){
                edit = false;
            }

            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('stores.store'),
                data: {
                    stores: [$scope.store],
                    client_id: $scope.client_id,
                    project_id: $scope.project_id ? $scope.project_id : '',
                    is_edit: edit
                }
            }).success(function (data) {
                $scope.saving = false;
                success_toast('Store saved');
            }).error(function (data){
                $scope.stores = data.stores;
                $scope.saving = false;
                angular.forEach(data.errors, function (errors, key) {
                    error_toast(errors.errors);
                });
            });
        }

        $scope.edit = function()
        {
            var survey = $('#surveyUpload').hasClass('ng-invalid');
            var idate = $('#installationDate').hasClass('ng-invalid');

            if(survey || idate){

            }else{
                $scope.editing = !$scope.editing;
                if ($scope.editing) {
                    saveStore(true);
                }
            }
        };

        setTimeout(function(){$scope.getFeeds()}, 200);


        $scope.prepareCreatePost = function() {
          if ($scope.post.custom_tag) {
              $('#add-new-tag').openModal();
          } else {
              $scope.createPost();
          }
        };

        $scope.setTagScope = function(scope) {
            $scope.post.tag_scope = scope;
            $('#add-new-tag').closeModal();
            $scope.createPost();
        };

        //Image functions
        $scope.createPost = function()
        {
            $scope.$parent.saving = true;
            var $client_id,
                $project_id,
                $store_id;

            $client_id = $scope.client_id ? $scope.client_id : '';
            $project_id = $scope.project_id ? $scope.project_id : '';
            $store_id = $scope.store_id ? $scope.store_id : '';

            if ($scope.post.files) {
                $scope.post.files = $filter('orderBy')($scope.post.files, 'name');
            }

            $scope.saving = true;
            Upload.upload({
                url : laroute.route('posts.store'),
                method: 'POST',
                sendFieldsAs: 'form',
                fields: {
                    post: $scope.post,
                    client_id: $client_id,
                    project_id: $project_id,
                    store_id: $store_id
                }
            }).success(function(data){
                $scope.getFeeds();
                $scope.post.files = [];
                $scope.invalid_files = [];
                $scope.post.message = '';
                $scope.post.custom_tag = '';
                success_toast('Message Posted');
                $scope.saving = false;
                $scope.$parent.saving = false;

                if (md.mobile()) {
                    $scope.$parent.resetFeeds();
                    $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
                }
            }).error(function (data){
                $scope.saving = false;
                error_toast(data.errors);
            });
        };

        $scope.removeFile = function(file) {
            $scope.max_files_error = false;
            $scope.invalid_files = [];

            var fileIndex = $scope.post.files.indexOf(file);
            $scope.post.files = $filter('filter')($scope.post.files, function(value, index) { return index !== fileIndex;});
        };

        $scope.switchView = function(view)
        {
            $scope.active_view = view;
            saveSettings();
        };

        //Show Delete
        $scope.showDelete = function(feed_item)
        {
            feed_item.show_delete = true;
        };
        $scope.hideDelete = function(feed_item)
        {
            if (md.mobile()) {
                feed_item.show_delete = true;
            } else {
                feed_item.show_delete = false;
            }
        };
        $scope.checkShowDelete = function()
        {
            if (md.mobile()) {
                return true;
            }
            return false;
        };

        //Slider and Expand Feature
        $scope.toggleFeedItem = function(feed_item, event) {
            if ($(event.target).is('a')
                || ($(event.target).is('img') && $(event.target).parent().is('a'))
                || ($(event.target).is('i') && $(event.target).parent().is('a'))
                || $(event.target).hasClass('ir-slide')
            )
                return;

            feed_item.expanded = feed_item.expanded ? false : true;
        };

        $scope.goToEditPost = function(id) {
            location.href = laroute.url('feed', [id]);
        };

        $scope.setCurrentSlideIndex = function (index) {
            $scope.fullscreen_item.current_index = index;
        };

        $scope.isCurrentSlideIndex = function (feed_item, index) {
            return feed_item.current_index === index;
        };

        $scope.prevSlide = function (id, feed_item, files, event) {
            $('#ir-slider-'+id+' .ir-slide').removeClass('ir-slide-animation-left');
            $('#ir-slider-'+id+' .ir-slide').addClass('ir-slide-animation-right');
            feed_item.current_index = (feed_item.current_index < files.length - 1) ? ++feed_item.current_index : 0;
            $scope.setCurrentSlideIndex(feed_item.current_index);
            event.stopPropagation();
        };

        $scope.nextSlide = function (id, feed_item, files, event) {
            $('#ir-slider-'+id+' .ir-slide').removeClass('ir-slide-animation-right');
            $('#ir-slider-'+id+' .ir-slide').addClass('ir-slide-animation-left');
            feed_item.current_index = (feed_item.current_index > 0) ? --feed_item.current_index : files.length - 1;
            $scope.setCurrentSlideIndex(feed_item.current_index);
            event.stopPropagation();
        };

        //Fullscreen
        $scope.showFullscreen = function(feed_item, index, event) {
            $('body').css({
                overflow: 'hidden'
            });
            $scope.fullscreen = true;
            $scope.fullscreen_item = feed_item;
            $scope.fullscreen_item.show_save_changes = false;
            $scope.setCurrentSlideIndex(index);
            event.stopPropagation();
        };

        $scope.showFullscreenById = function(feed_item, id, event) {
            $('body').css({
                overflow: 'hidden'
            });
            $scope.fullscreen = true;
            $scope.fullscreen_item = feed_item;
            for (var i = 0; i < feed_item.files.length; i++) {
                if (feed_item.files[i].id == id) {
                    $scope.setCurrentSlideIndex(i);
                    break;
                }
            }
            event.stopPropagation();
        };

        $scope.showFilesViewFullscreenById = function(id, files, event) {
            var feed_item = {};
            feed_item.files = files;
            feed_item.current_index = 0;
            $scope.showFullscreenById(feed_item, id, event);
            event.stopPropagation();
        };

        $scope.compressFullscreen = function() {
            $('body').css({
                overflow: ''
            });
            $scope.fullscreen = false;
            $scope.fullscreen_item = {};

        };
        $scope.closeFullscreen = function(event) {
            if (!$(event.target).is("#ir-fullscreen"))
                return;
            $scope.closeEditImg();
            $scope.compressFullscreen();
        };

        $('#ir-fullscreen .ir-fullscreen-container').css({
            height: $( window ).height()
        });
        $(window).on('resize', function() {
            $('#ir-fullscreen .ir-fullscreen-container').css({
                height: $( window ).height()
            });
        });

        //edit image
        $scope.editFullscreenImage = function() {
            $scope.loading = true;
            $scope.base64Img = '';

            var index = $scope.fullscreen_item.current_index;
            var url = $scope.fullscreen_item.files[index].file_url;
            $scope.editFilename = $scope.fullscreen_item.files[index].file_name;

            return $http({
                method : "GET",
                url : laroute.route('main.getBase64UriFromImage') + '?url='+url
            }).success(function(data){
                if (data) {
                    $scope.base64Img = data;
                    $scope.editingFullscreenImg = true;
                } else {
                    $scope.editingFullscreenImg = false;
                }
                $scope.loading = false;
            });
        };

        $scope.closeEditImg = function() {
            $scope.editingFullscreenImg = false;
        };

        $scope.saveImgEdited = function() {
            if($scope.editFilename) {
                var index = $scope.fullscreen_item.current_index;
                var canvas = $('.darkroom-image-container .lower-canvas').get(0);
                var dataURL = canvas.toDataURL("image/png");
                $scope.loading = true;

                return $http({
                    method : "POST",
                    url : laroute.route('dashboard.update_feed_image'),
                    data: {
                        image: dataURL,
                        filename: $scope.editFilename,
                        old_file: $scope.fullscreen_item.files[index].id
                    }
                }).success(function(data){
                    $scope.loading = false;
                    success_toast('Image changes saved');
                    $scope.fullscreen_item.files[index].clean_file_url =  data.file_url;
                    $scope.fullscreen_item.files[index].file_url =  data.file_url;
                    $scope.fullscreen_item.files[index].file_name = $scope.editFilename;
                    $scope.updateFilesInfo($scope.fullscreen_item.files[index].id, $scope.fullscreen_item.files[index]);
                    $scope.editingFullscreenImg = false;
                });
            }
            else{
                error_toast('You should type a filename');
            }
        };

        $scope.updateFilesInfo = function(id, file)
        {
            if($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {
                    if(feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {
                            if (files_to_update.id == id) {
                                files_to_update.file_url = file.file_url;
                                files_to_update.clean_file_url = file.clean_file_url;
                                files_to_update.file_name = file.file_name;
                                files_to_update.filtered_comments = file.filtered_comments;
                                files_to_update.comments_count = file.comments_count;
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (files_to_update.id == id) {
                        files_to_update.file_url = file.file_url;
                        files_to_update.clean_file_url = file.clean_file_url;
                        files_to_update.file_name = file.file_name;
                        files_to_update.filtered_comments = file.filtered_comments;
                        files_to_update.comments_count = file.comments_count;
                    }
                });
            }
        };

        $scope.createComment = function(index)
        {
            if( $scope.fullscreen_item.files[index] ) {
                $scope.saving = true;
                $('.ir-comment-field').blur();
                $http({
                    method: "POST",
                    url: laroute.route('comments.store'),
                    data: {
                        text: $scope.fullscreen_item.comment_text,
                        entity_id: $scope.fullscreen_item.files[index].id,
                        entity: 'post_file'
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    $scope.fullscreen_item.comment_text = '';
                    $scope.fullscreen_item.files[index].filtered_comments = data.comments;
                    $scope.fullscreen_item.files[index].comments_count = data.comments.length;
                    $scope.updateFilesInfo($scope.fullscreen_item.files[index].id, $scope.fullscreen_item.files[index]);
                    success_toast('Comment Saved');
                }).error(function (data){
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.createReply = function(comment)
        {
            if( comment.commentable_id ) {
                $scope.saving = true;
                $('.ir-reply-field').blur();
                $http({
                    method: "POST",
                    url: laroute.route('comments.store'),
                    data: {
                        text: comment.reply_text,
                        entity_id: comment.commentable_id,
                        entity: 'post_file',
                        parent_id: comment.id
                    }
                }).success(function (data) {
                    $scope.saving = false;
                    comment.reply_text = '';
                    comment.filtered_replies = data.comments;
                    success_toast('Reply Saved');
                }).error(function (data){
                    $scope.saving = false;
                    error_toast(data.errors);
                });
            }
        };

        $scope.approveFile = function(index)
        {
            $scope.fullscreen_item.show_save_changes = true;
            $scope.fullscreen_item.files[index].action = 'approve';
        };

        $scope.rejectFile = function(index)
        {
            $scope.fullscreen_item.show_save_changes = true;
            $scope.fullscreen_item.files[index].action = 'reject';
        };

        $scope.saveFilesChanges = function(){
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.approve_reject_files'),
                data: {
                    files: $scope.fullscreen_item.files
                }
            }).success(function (data) {
                $scope.saving = false;

                if ($scope.fullscreen_item.post)
                    $scope.fullscreen_item.post.status = data.post.status;

                $scope.fullscreen_item.files = data.files;
                $scope.fullscreen_item.show_save_changes = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('Files Updated');
            });
        };

        $scope.$on('setSettings', function(e, data) {
            if(!data.setting.hidden_columns == ''){
                $scope.tableColumns = JSON.parse(data.setting.hidden_columns);
            }
            var sort = data.setting.order.split(' ');
            $scope.sort = sort[0];
            $scope.sort_dir = sort[1];

            $scope.active_view = data.setting.view;
        });


        //Delete Feed Item
        $scope.setDeleteVal = function(index)
        {
            $("#delete-post").openModal();
            $scope.deleteIndex = index;
        };

        //Delete Post file
        $scope.setDeleteFile = function(index)
        {
            $("#delete-post-file").openModal();
            $scope.deleteFileId = index;
        };

        //Delete Comment 
        $scope.setDeleteComment = function(index)
        {
            $("#delete-comment").openModal();
            $scope.deleteCommentId = index;
            $scope.compressFullscreen();
        };

        $scope.deletePost = function()
        {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('dashboard.delete_feed', {
                    feeds: $scope.feed_items[$scope.deleteIndex].id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.getFeeds();
                success_toast('Post deleted');
            });
        };

        $scope.deletePostFile = function()
        {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('posts.delete_file', {
                    file_id: $scope.deleteFileId
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.getFeeds();
                success_toast('Post File deleted');
            });
        };

        $scope.deleteComment = function()
        {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('comments.destroy', {
                    comment: $scope.deleteCommentId
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.compressFullscreen();
                $scope.getFeeds();
                success_toast('Comment deleted');
            });
        };

        //Approve / Reject All
        $scope.setApproveVal = function(index, event)
        {
            $scope.approveIndex = index;
            $scope.approveAll();
            event.stopPropagation();
        };
        $scope.setRejectVal = function(index, event)
        {
            $scope.rejectIndex = index;
            $scope.rejectAll();
            event.stopPropagation();
        };
        $scope.approveAll = function()
        {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.approve_all_files', {
                    posts: $scope.feed_items[$scope.approveIndex].post.id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('All Approved');
            });
        };
        $scope.rejectAll = function()
        {
            $scope.saving = true;
            $http({
                method: "POST",
                url: laroute.route('posts.reject_all_files', {
                    posts: $scope.feed_items[$scope.rejectIndex].post.id
                })
            }).success(function (data) {
                $scope.saving = false;
                $scope.updatePostsAndFilesStatus(null, null, data.post.id, data.post.status, data.files);
                success_toast('All Rejected');
            });
        };

        $scope.updateFileStatus = function(id, status)
        {
            if($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {
                    if(feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {
                            if (files_to_update.id == id) {
                                files_to_update.status = status;
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (files_to_update.id == id) {
                        files_to_update.status = status;
                    }
                });
            }
        };

        $scope.updatePostsAndFilesStatus = function(id, status, post_id, post_status, files)
        {
            if($scope.feed_items) {
                $scope.feed_items.forEach(function (feed_item) {

                    if(post_id && post_status) {
                        if (feed_item.post && feed_item.post.id == post_id) {
                            feed_item.post.status = post_status;
                        }
                    }

                    if(feed_item.files) {
                        feed_item.files.forEach(function (files_to_update) {

                            if (id && status && files_to_update.id == id) {
                                files_to_update.status = status;
                            }

                            if (files) {
                                for (var i = 0; i < files.length; i++) {
                                    if (files[i].id == files_to_update.id) {
                                        files_to_update.status = files[i].status;
                                    }
                                }
                            }
                        });
                    }
                });
            }

            if ($scope.files) {
                $scope.files.forEach(function (files_to_update) {
                    if (id && status && files_to_update.id == id) {
                        files_to_update.status = status;
                    }

                    if (files) {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].id == files_to_update.id) {
                                files_to_update.status = files[i].status;
                            }
                        }
                    }
                });
            }
        };

        function getSettings() {
            $http({
                method : "GET",
                url : laroute.route('dashboard.get_settings')
            }).success(function(data) {
                if(data.setting) {
                    if(!data.setting.hidden_columns == ''){
                        $scope.tableColumns = JSON.parse(data.setting.hidden_columns);
                    }
                    var sort = data.setting.order.split(' ');
                    $scope.sort = sort[0];
                    $scope.sort_dir = sort[1];
                    $scope.active_view = 'list';
                }
            });
        }

        $scope.loadMorePosts = function()
        {
            if($scope.active_view !== 'table') {
                if ($scope.loading)
                    return;

                if ($scope.show_more){
                    $scope.page++;
                    $scope.getFeeds();
                }
            }
        };

        $scope.filterTag = function(tag) {
            //call to functions in FilterControlller
            $scope.$parent.tag_id = tag.id;
            $scope.$parent.setCurrentTag();
        };

        $scope.setPostTag = function(tag) {
            $scope.post.tag_id = tag.id;
            $scope.post.custom_tag = '';
        };

        $scope.changeCustomTag = function() {
            if ($scope.post.custom_tag) {
                $scope.post.tag_id = null;
            }
        };

        //Mobile
        if (md.mobile()) {
            $scope.files_limit = 4;
        } else {
            $scope.files_limit = 12;
            $scope.is_checked = false;
        }

        $scope.NewPostIsDisabled = function ()
        {
            if(typeof $scope.post.tag_id != 'undefined'){
                if((!$scope.post.tag_id && !$scope.post.custom_tag) || (!$scope.post.message && (!$scope.post.files || $scope.post.files.length == 0))){
                    return true;
                }
            }
            return false;
        };

        $scope.goBackRouter = function (projectPath) {
            if ($scope.tag_id) {
              window.location.reload();
            }
            else {
              window.location.href = projectPath + "/" + $scope.project_id;
            }
          }

          
        $scope.$on('changeTag', function(e, tag) {

            if(tag == 'manage'){
                window.location = laroute.route('tags.index');
            }
            $scope.tag_id = tag;
            $scope.getFeeds();
        });

        $scope.$on('resetFeeds', function(e, data) {
            $scope.getFeeds();
        });

        $scope.$on('setFeeds', function(e, data) {
            $scope.feed_items = data.feed_items;
            $scope.feed_items_table = data.feed_items_table;
        });

        $scope.$on('setLoading', function(e, data) {
            $scope.loading = data;
        });

        $scope.$on('changeView', function(e, data) {
            $scope.switchView(data);
        });

    }])
