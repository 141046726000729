angular.module('ireactApp.projects', ['ireactApp.controllers'])

/**
 * Project Index
 */
    .controller('ProjectsListController', ['$scope', '$http', '$mdDialog', 'orderByFilter', function ($scope, $http, $mdDialog, orderBy) {
        $scope.date_format = date_format; //global variable at master layout
        $scope.projects = [];
        $scope.loading = true;
        $scope.order_by = 'name';
        $scope.order_dir = false;

        $scope.client_id = null;
        $scope.project_id = null;

        $scope.orderBy = function(order_by)
        {
            $scope.order_dir = (order_by !== null && $scope.order_by === order_by)
                ? !$scope.order_dir : false;

            $scope.projects = orderBy($scope.projects, order_by, $scope.order_dir);
            $scope.order_by = order_by;

        };

        $scope.redirect = function(index)
        {
            $scope.project_id = $scope.projects[index].id;

            window.location = laroute.route('projects.index')+'/'+$scope.projects[index].id;
        };

        //Set Projects from Filter Controller
        $scope.$on('setProjects', function(e, projects) {
            $scope.projects = projects;
            $scope.pager_items = projects.length;
            $scope.pager_pages = Math.ceil($scope.pager_items / $scope.pager_show);
            $scope.paginate($scope.pager_current);
            $scope.loading = false;
        });


        //pager
        $scope.pager_current = 1;
        $scope.pager_items = 0;
        $scope.pager_start = 0;
        $scope.pager_end = 0;
        $scope.pager_pages = 1;
        $scope.pager_show = 25;

        $scope.paginate = function(page)
        {
            $scope.pager_current = page;
            $scope.pager_end = $scope.pager_show * page;
            $scope.pager_start = $scope.pager_end - $scope.pager_show;
        };

        $scope.switchPage = function(index)
        {
            $scope.pager_current = index;
            $scope.paginate(index);
        };

        $scope.prevPage = function()
        {
            if ($scope.pager_current !== 1) {
                $scope.pager_current = $scope.pager_current - 1;
                $scope.paginate($scope.pager_current);
            }
        };

        $scope.nextPage = function()
        {
            if ($scope.pager_current !== $scope.pager_pages) {
                $scope.pager_current = $scope.pager_current + 1;
                $scope.paginate($scope.pager_current);
            }
        };

    }])

    /**
     * Project Index
     */
    .controller('ProjectsIndexController', ['$scope', '$http', '$mdDialog', 'orderByFilter', function ($scope, $http, $mdDialog, orderBy) {
        $scope.date_format = date_format; //global variable at master layout
        $scope.projects = [];
        $scope.loading = true;
        $scope.saving = false;
        $scope.saved = false;
        $scope.order_by = 'name';
        $scope.order_dir = false; // true == 'desc'

        $scope.client_id = null;
        $scope.project_id = null;

        $scope.getProjects = function()
        {
            $scope.loading = true;
            $http({
                method : "GET",
                url : laroute.route('projects.get_data', {
                    client_id: $scope.client_id,
                    order_by: $scope.order_by,
                    order_dir: $scope.order_dir ? 'desc' : 'asc'
                })
            }).success(function(data){
                $scope.projects = data.projects;
                $scope.loading = false;
            });
        };

        $scope.orderBy = function(order_by)
        {
            $scope.order_dir = (order_by !== null && $scope.order_by === order_by)
                ? !$scope.order_dir : false;

            $scope.projects = orderBy($scope.projects, order_by, $scope.order_dir);
            $scope.order_by = order_by;

        };

        $scope.saveProjects = function() {

            if ($scope.frmManageProjects.$valid) {
                $scope.saving = true;
                $http({
                    method: "PUT",
                    url: laroute.route('projects.update_all'),
                    data: {
                        client_id: $scope.client_id,
                        projects: $scope.projects,
                        order_by: $scope.order_by,
                        order_dir: $scope.order_dir
                    }
                }).success(function (data) {
                    $scope.projects = data.projects;
                    $scope.saving = false;
                    success_toast('Projects updated');
                }).error(function (data){
                    $scope.saving = false;
                    angular.forEach(data.errors, function (errors, key) {
                        error_toast(errors.errors);
                    });
                });
            } else {
                $scope.frmManageProjects.submitted = true;
            }
        };

        $scope.setDeleteVal = function(index){
            $scope.index = index;
            $('#delete-project').openModal();
        };

        $scope.deleteProject = function()
        {
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('projects.destroy', {
                    projects: $scope.projects[$scope.index].id,
                    order_by: $scope.order_by,
                    order_dir: $scope.order_dir,
                    client_id: $scope.client_id
                })
            }).success(function (data) {
                $scope.projects = data.projects;
                $scope.saving = false;
                success_toast('Project deleted');
            }).error(function(data) {
                if(data.error == 'project not empty'){
                    error_toast('You must contact an administrator in order to delete a project with stores');
                }
                $scope.saving = false;
            });
        };

        $scope.$watch('client_id', function(newVals, oldVals){
            if ($scope.client_id) {
                $scope.getProjects();
            }
        }, true);

    }])

	/**
     * New Project
     */
    .controller('NewProjectController', ['$scope', '$http', 'Upload', '$timeout', function ($scope, $http, Upload, $timeout) {

        $scope.stores = [];
        $scope.project = {};
        $scope.loading = true;
        $scope.saving = false;
        $scope.more_stores = 5;

        $scope.$watch('client', function(newVals, oldVals){
            if ($scope.client.id) {
                $scope.project.client_id = $scope.client.id;
            }
        }, true);

        $scope.pushStore = function()
        {
            $scope.stores.push({
                id: null,
                number: '',
                name: '',
                address: '',
                city: '',
                phone: '',
                required: false
            });
        };

        $scope.addStoreRow = function(index)
        {
            if (index == $scope.stores.length -1) {
                $scope.pushStore();
            }
        };

        $scope.addStores = function()
        {
            if (parseInt($scope.more_stores) > 0) {
                for (var i = 0; i < $scope.more_stores; i++) {
                    $scope.pushStore();
                }
            }
        };

        $scope.searchStore = function($index)
        {
            if ($scope.stores[$index].number) {
                $http({
                    method: "GET",
                    url: laroute.route('clients.get_store_by_number', {
                        clients: $scope.client.id,
                        number: $scope.stores[$index].number
                    })
                }).success(function (data) {
                    if (data.store) {
                        $scope.stores[$index].id = data.store.id;
                        $scope.stores[$index].name = data.store.name;
                        $scope.stores[$index].address = data.store.address;
                        $scope.stores[$index].city = data.store.city;
                        $scope.stores[$index].phone = data.store.phone;
                    } else {
                        $scope.stores[$index].id = null;
                    }
                }).error(function (data){
                    error_toast(data.errors);
                });
            } else {
                $scope.stores[$index].id = null;
            }
        };

        $scope.removeStoreRow = function(index)
        {
            $scope.stores.splice(index, 1);
        };

        $scope.uploadFileStore = function(file){
            $scope.saving = true;
            Upload.upload({
                url:  laroute.route('projects.process_stores_file'),
                method: "POST",
                data: {
                    file: file,
                    client: $scope.client.id
                }
            }).success(function (data, status, headers, config) {
                if(!data.stores.length) {
                    error_toast('File could not be imported');
                }
                else {
                    if($scope.stores.length == 1 && !$scope.stores[0].number && !$scope.stores[0].name
                        && !$scope.stores[0].address && !$scope.stores[0].city && !$scope.stores[0].phone) {
                        $scope.stores.splice(0, 1);
                    }

                    angular.forEach(data.stores, function($elem){
                        $timeout(function() {
                            $scope.stores.push($elem);
                        }, 1);

                    });
                    success_toast(data.stores.length + ' Rows imported');
                }
                $scope.saving = false;
                $scope.project.file = null;

            }).error(function (data){
                $scope.saving = false;
                angular.forEach(data.errors, function (errors, key) {
                    error_toast(errors.errors);
                });
            });
        };

        $scope.saveProject = function() {
            if ($scope.frmNewProject.$valid) {
                $scope.saving = true;
                $http({
                    method: "POST",
                    url: laroute.route('projects.store'),
                    data: {
                        project: $scope.project,
                        stores: []
                    }
                }).success(function (data) {
                    $scope.stores = [];
                    $scope.project.name = '';
                    $scope.project.file = null;
                    $scope.project = data.project;
                    $scope.pushStore();
                    $scope.saving = false;
                    $scope.saved = true;
                    $scope.frmNewProject.submitted = false;
                    $scope.frmNewProject.$setPristine();
                    success_toast('Project saved');
                }).error(function (data){
                    $scope.saving = false;
                    angular.forEach(data.errors, function (errors, key) {
                        error_toast(errors.errors);
                    });
                });
            } else {
                $scope.frmNewProject.submitted = true;
            }
        };

        $scope.pushStore();
    }])
