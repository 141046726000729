'use strict';

var app = angular.module('ireactApp', [
    'ngFileUpload',
    'ngMessages',
    'ngSanitize',
    'ngAnimate',
    'ngMaterial',
    'infinite-scroll',
    'ui.autocomplete',
    'angular-darkroom',
    'angular-underscore',
    'angucomplete-alt',
    //'ireactApp.services',
    'ireactApp.filter',
    'ireactApp.filters',
    'ireactApp.controllers',
    'ireactApp.directives',
    'ireactApp.users',
    'ireactApp.profile',
    'ireactApp.clients',
    'ireactApp.projects',
    'ireactApp.stores',
    'ireactApp.tags',
    'ireactApp.feeds',
    'ireactApp.dashboard',
    'ireactApp.dashboard-admin'
]);