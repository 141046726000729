angular.module('ireactApp.filter', ['ireactApp.controllers'])

/**
 * Filters
 */

.controller('FilterController', ['$scope', '$http', '$timeout', function ($scope, $http, $timeout) {
    $scope.client = {};
    $scope.clients = [];
    $scope.project = {};
    $scope.projects = [];
    $scope.store = {};
    $scope.stores = [];
    $scope.tag = {};
    $scope.selectedClient = null;
    $scope.selectedProject = null;
    $scope.redirect = true;
    $scope.storesCallback = function() { setCurrentStore() };
    $scope.targetURL = null;

    $scope.client_id = null;
    $scope.project_id = null;
    $scope.store_id = null;

    $scope.bread_label = '';
    $scope.bread_min = true;
    $scope.is_mobile = false;

    $scope.tableColumns = {
        name: false,
        number: false,
        mall_name: false,
        address: false,
        city: false,
        state: false,
        country: false,
        survey_upload: true,
        install_check_in: true,
        install_check_out: true,
        revisit_needed: true,
        notes: true
    };


    setTimeout(function(){saveSettings();},2000);

    function saveSettings() {
        $http({
            method : "POST",
            url : laroute.route('dashboard.store_settings'),
            data: {
                hidden_columns: JSON.stringify($scope.tableColumns),
                order: 'asc',
                view: $scope.active_view,
                client: $scope.client_id,
                project: $scope.project_id,
                store: $scope.store_id,
                tag: null
            }
        });
    }

    function getSettings() {
        $http({
            method : "GET",
            url : laroute.route('dashboard.get_settings')
        }).success(function(data) {

            if(data.setting) {
                $scope.$broadcast ('setSettings', data);

                var tables = [];
                if(!data.setting.hidden_columns == ''){
                    tables = JSON.parse(data.setting.hidden_columns);
                }

                if (tables instanceof Array){
                    //do nothing
                }else if (tables instanceof Object) {
                    $scope.tableColumns = tables;
                }

                var sort = data.setting.order.split(' ');
                $scope.sort = sort[0];
                $scope.sort_dir = sort[1];
                $scope.active_view = data.setting.view;

                $scope.getClients();
            }
        });
    }

    $scope.loadFilters = function()
    {

    };

    $scope.$on('expandSearch', function (event) {
        if(md.mobile() && $(window).width() <= 600) {
            $('.nav-left').css('position', 'absolute');
            $('.nav-left').removeClass('s4').addClass('s12');
            $('.search-input').removeClass('hide-on-small-and-down');
        }
        $('.search-input').focus();
    });

    $scope.hideSearch = function(){
        $('#closeSearch').remove();
        if(md.mobile()) {
            $('.nav-left').addClass('s4').removeClass('s12');
            $('.nav-left').css('position', 'relative');
            $('.search-input').addClass('hide-on-small-and-down');
        }
    };

    $scope.querySearch = function (query, source) {

        var src = null;
        if (source == 'clients') {
            src = $scope.clients;
        } else if (source == 'projects') {
            src = $scope.projects;
        } else if (source == 'stores') {
            src = $scope.stores;
        } else if (source == 'tags') {
            src = $scope.tags;
        }

        var selectData = _.map(src, function (item) {

            var id = null;
            if (item.id) {
                id = item.id;
            } else {
                id = item.value;
            }

            var city = item.city ? item.city : '';
            var location = (item.mall_name ? item.mall_name + ' - ' : '') + city;

            return {display: item.name, value: id, location: location};
        }

        );
        var results = query ? selectData.filter(createFilterFor(query)) : selectData,
            deferred;
        if ($scope.simulateQuery) {
            deferred = $q.defer();
            $timeout(function () {
                deferred.resolve(results);
            }, Math.random() * 1000, false);
            return deferred.promise;
        } else {
            return results;
        }
    };

    $scope.getClients = function()
    {
        return $http({
            method : "GET",
            url : laroute.route('clients.get_data', {
                order_by: 'name',
                order_dir: 'asc'
            })
        }).success(function(data) {
            $scope.$broadcast ('setClients', data.clients);
            data.clients = [{name:'All Clients', id: 0, value: 'All Clients'}].concat(data.clients);
            $scope.clients = data.clients;
            setCurrentUser();
        });
    };

    $scope.selectClient = function(item){

        if(typeof item != 'undefined') {
            if (item.value == "All Clients") {
                $scope.client_id = '';
                $scope.project_id = '';
                $scope.store_id = '';
                saveSettings();
                window.location = laroute.route('dashboard');
            } else {
                $scope.client_id = item.value;
                $scope.targetURL = laroute.route('clients.index') + '/' + item.value;

                if ($scope.redirect) {
                    $scope.project_id = '';
                    $scope.store_id = '';
                    saveSettings();
                    window.location = $scope.targetURL;
                }

                setCurrentProject();
            }
        }

    };

    $scope.selectProject = function(item){

        if(typeof item != 'undefined'){
            if(item.value == "All Projects"){
                $scope.project_id = '';
                $scope.store_id = '';
                saveSettings();
                window.location = laroute.route('clients.index')+'/'+$scope.client_id;
            }else{
                $scope.project_id = item.value;
                $scope.targetURL = laroute.route('projects.index')+'/'+item.value;

                if($scope.redirect){
                    $scope.store_id = '';
                    saveSettings();
                    window.location = $scope.targetURL;
                }

                setCurrentStore();
            }
        }
    };

    $scope.selectStore = function(item){

        if(typeof item != 'undefined') {
            if (item.value == "All Stores") {
                $scope.store_id = '';
                saveSettings();
                window.location = laroute.route('projects.index') + '/' + $scope.project_id;
            } else {
                $scope.store_id = item.value;
                $scope.targetURL = laroute.route('stores.index') + '/' + item.value + '/' + $scope.project_id;

                if ($scope.redirect) {
                    saveSettings();
                    window.location = $scope.targetURL;
                }
            }
        }
    };

    $scope.selectTag = function(item){

        var itm = 0;
        if (typeof(item ) !== 'undefined'){
            itm = item.value;
        }

        $scope.$broadcast ('changeTag', itm);
    };

    function setCurrentUser(){
        if ($scope.client_id) {
            $scope.client = _.find($scope.clients, function(c){ return c.id == $scope.client_id });
            $scope.redirect = false;
            $scope.selectedClient = { display: $scope.client.name, value: $scope.client.id };
            $scope.getProjects(function(){ setCurrentProject() });

            setRedirect(true);
        }
        redirectLocation();
    }

    function setCurrentProject(){
        if ($scope.project_id) {
            $scope.project = _.find($scope.projects, function(c){ return c.id == $scope.project_id });
            $scope.redirect = false;
            if(typeof $scope.project != 'undefined'){
                $scope.selectedProject = { display: $scope.project.name, value: $scope.project.id };
            }
            setRedirect(true);
        }
    }

    function setCurrentStore(){

        if ($scope.store_id) {

            $scope.store = _.find($scope.stores, function(c){ return c.id == $scope.store_id });
            $scope.redirect = false;
            if(typeof $scope.store != 'undefined') {
                $scope.selectedStore = {display: $scope.store.name, value: $scope.store.id};
            }
            setRedirect(true);
        }

    }

    $scope.setCurrentTag = function() {
        $scope.tag = _.find($scope.tags, function(c){ return c.id == $scope.tag_id });
        $scope.redirect = false;
        if(typeof $scope.tag != 'undefined') {
            $scope.selectedTag = {display: $scope.tag.name, value: $scope.tag.id};
        }
        setRedirect(true);
    };

    function redirectLocation(){
        var url = window.location.href.toString().split(window.location.host)[1];

        //Redirect if is not in the target URL
        setTimeout(function(){
            if($scope.store_id) {
                if (url !== $scope.targetURL) {
                    //window.location = $scope.targetURL;
                }
            }

            if($scope.project_id) {
                if (url !== $scope.targetURL) {
                    //window.location = $scope.targetURL;
                }
            }

            if($scope.client_id) {

                if ($scope.is_client){

                }else{
                    if (url !== $scope.targetURL) {
                        //window.location = $scope.targetURL;
                    }
                }
            }
        }, 500);

    }

    function setRedirect(value){
        setTimeout(function(){ $scope.redirect = value; }, 500);
    }

    $scope.getProjects = function (callback) {
        return $http({
            method : "GET",
            url : laroute.route('projects.get_data', {
                client_id: $scope.client_id,
                order_by: 'name',
                order_dir: 'asc'
            })
        }).success(function(data) {
            $scope.$broadcast ('setProjects', data.projects);
            data.projects = [{name:'All Projects', id: 0, value: 'All Projects'}].concat(data.projects);
            $scope.projects = data.projects;

            if(typeof callback !== 'undefined'){
                callback();
            }

            if($scope.project_id && $scope.project_id > 0){
                $scope.getStores();
            }
        });
    };

    $scope.getStores = function(){
        return $http({
            method : "GET",
            url : laroute.route('stores.get_data', {
                client_id: $scope.client_id,
                project_id: $scope.project_id,
                order_by: 'name',
                order_dir: 'asc'
            })
        }).success(function(data) {
            $scope.$broadcast ('setStores', data.stores);
            data.stores = [{name:'All Stores', id: 0, value: 'All Stores'}].concat(data.stores);
            $scope.stores = data.stores;
            $scope.storesCallback();
        });
    };

    $scope.searchTextChange = function(searchText){

    };

    function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);

        return function filterFn(client) {
            var temp1 = (angular.lowercase(client.value.toString()).indexOf(lowercaseQuery) !== -1);
            var temp2 = (angular.lowercase(client.display).indexOf(lowercaseQuery) !== -1);
            return (temp1 || temp2);
        };
    }

    $scope.clearFilters = function()
    {
        if (!$scope.is_client) {
            $scope.client_id = '';
        }

        $scope.project_id = '';
        $scope.store_id = '';
        saveSettings();

        window.location = laroute.route('dashboard');
    };

    getSettings();

    $scope.setNewPostLabel = function()
    {
        $scope.new_post_label = 'Comments...';
    };

    $scope.get_bread_label = function() {

        if((typeof($scope.store) != 'undefined') && (typeof($scope.store.name) != 'undefined')){
            return '...' + $scope.store.name;
        }
        if((typeof($scope.project) != 'undefined') && (typeof($scope.project.name) != 'undefined')){
            return '...' + $scope.project.name;
        }
        if((typeof($scope.client) != 'undefined') && (typeof($scope.client.name) != 'undefined')){
            return '...' + $scope.client.name;
        }

        return '';
    };

    $scope.setBreadcrumbVisible = function(value) {
        $scope.bread_min = value;
    };


    if(md.phone()){
        $scope.is_mobile = true;
    }

    $scope.resetFeeds = function(){
        $scope.$broadcast("resetFeeds",'test');
    };

    $scope.searchFeeds = function (queryObj, reset) {

        if (queryObj){
             var target = queryObj.description;

            if(typeof(target.project_id) != 'undefined' && target.type == 'store'){
                window.location = laroute.route('stores.show', {
                    id: target.id,
                    project: target.project_id
                });
            }
            if(typeof(target.client_id) != 'undefined' && target.type == 'project'){
                window.location = laroute.route('projects.show', {
                    projects: target.id
                });
            }
            if(typeof(target.id) != 'undefined' && target.type == 'client'){
                window.location = laroute.route('clients.show', {
                    clients: target.id
                });
            }
        }

        if (queryObj) {
            $scope.search_query_object = queryObj;
            $scope.search_query_text = queryObj.originalObject.query;
            $scope.search_id = $scope.search_query_object.originalObject.id;
            $scope.search_query = $scope.search_query_object.originalObject.query ? $scope.search_query_object.originalObject.query : false;
            $scope.search_type = $scope.search_query_object.originalObject.type ? $scope.search_query_object.originalObject.type : false;

            $scope.getPosts(false, reset);

            if (md.mobile()) {
                $scope.hideSearch();
            }
        }

    };

    $scope.getPosts = function(initial, reset)
    {
        $scope.loading = true;
        $scope.$broadcast("setLoading", true);

        if (reset) {
            $scope.page = 1;
            $scope.show_more = true;
            $scope.feed_items = [];
            $scope.files = [];
        }

        return $http({
            method : "GET",
            url : laroute.route('dashboard.get_feed', {
                client_id: $scope.client_id ? $scope.client_id : '',
                project_id: $scope.project_id ? $scope.project_id : '',
                store_id: $scope.store_id ? $scope.store_id : '',
                tag_id: $scope.tag_id ? $scope.tag_id : '',
                page: $scope.page,
                table_page: $scope.table_page,
                search_id: $scope.search_id ? $scope.search_id : '',
                query: $scope.search_query ? $scope.search_query : '',
                type: $scope.search_type ? $scope.search_type : '',
                view: $scope.active_view,
                sort: 'feed_items.id',
                sort_dir: 'asc'
            })
        }).success(function(data) {
            var client_index = $scope.clients ? $scope.clients.indexOf($scope.client) : 0;
            var project_index = $scope.projects ? $scope.projects.indexOf($scope.project) : 0;
            var store_index = $scope.stores ? $scope.stores.indexOf($scope.store) : 0;
            var tag_index = $scope.tags ? $scope.tags.indexOf($scope.tag) : 0;

            $scope.clients = data.clients;
            $scope.projects = data.projects;
            $scope.stores = data.stores;
            $scope.tags = data.tags;

            $scope.$broadcast("setFeeds", data);
            $scope.$broadcast("setLoading", false);
            $scope.loading = false;

            if ($scope.is_admin && !md.phone()) {
                $scope.clients.unshift({name:'All Clients', value: 'all'}, {name: 'New Client...', value: 'add'}, {name: 'Manage Clients...', value: 'manage'});
            } else {
                $scope.clients.unshift({name:'All Clients', value: 'all'});
            }

            if ($scope.client_id && $scope.is_admin && !md.phone()) {
                if ($scope.projects.length > 0) {
                    $scope.projects.unshift({name: 'Manage Projects...', value: 'manage'});
                }
                $scope.projects.unshift({name: 'New Project...', value: 'add'});
            }
            if ($scope.client_id && $scope.project_id && $scope.is_admin && !md.phone()) {
                $scope.stores.unshift({name: 'Manage Stores...', value: 'manage'});
            }
            $scope.projects.unshift({name:'All Projects', value: 'all'});
            $scope.stores.unshift({name:'All Stores', value: 'all'});
            $scope.tags.unshift({name: 'Manage Folders...', value: 'manage'});
            $scope.tags.unshift({name:'All Folders', value: 'all'});

            if (initial) {
                if ($scope.is_client == 1 && $scope.clients.length > 0) {
                    $scope.client = $scope.clients[1];
                    $scope.client_id = $scope.client.id;
                    $scope.setNewPostLabel();
                } else {
                    if ($scope.client_id) {
                        $scope.client = _.find($scope.clients, function(c){ return c.id == $scope.client_id });
                    } else {
                        $scope.client = $scope.clients[0];
                    }
                }

                if ($scope.project_id) {
                    $scope.project = _.find($scope.projects, function(p){ return p.id == $scope.project_id });
                } else {
                    $scope.project = $scope.projects[0];
                }

                if ($scope.store_id) {
                    $scope.store = _.find($scope.stores, function(s){ return s.id == $scope.store_id });
                } else {
                    $scope.store = $scope.stores[0];
                }

                if ($scope.tag_id) {
                    $scope.tag = _.find($scope.tags, function(t){ return t.id == $scope.tag_id });
                } else {
                    $scope.tag = $scope.tags[0];
                }

                $scope.updateFilters();
            } else {
                $scope.client = $scope.clients[client_index];
                $scope.project = $scope.projects[project_index];
                $scope.store = $scope.stores[store_index];
                $scope.tag = $scope.tags[tag_index];
            }

            $scope.show_more = data.show_more;
            for (var i = 0; i < data.feed_items.length; i++) {
                $scope.feed_items.push(data.feed_items[i]);
            }
            $scope.feed_items_table = data.feed_items_table;

            $scope.pages = Math.floor( data.count_items / 10 ) + 1;
            for (var i = 0; i < data.files.length; i++) {
                $scope.files.push(data.files[i]);
            }

            $scope.loading = false;

            $( ".md-autocomplete-suggestions-container" ).each( function( index, element ){
                var innerContainer = $( this ).find('.md-virtual-repeat-sizer');
                if ($( this ).height() == 0) {
                    $(this).height(innerContainer.height());
                }
            });
        });
    };

    //Mobile Actions & Events
    $scope.toogleFilters = function() {

        $('#post_message').blur();
        if ($('#ir-feed-filters').hasClass('ir-filters-visible')) {
            $('#ir-feed-filters').css({
                "overflow": "hidden"
            });
            // $('#ir-main-content').removeClass('no-padding');
            $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
        } else {
            $('#ir-feed-filters, #ir-new-post-container').addClass('ir-filters-visible');
            // $('#ir-main-content').addClass('no-padding');
            $('#ir-feed-filters').one(transitionEvent, function () {
                $('#ir-feed-filters').css({
                    "overflow": "visible"
                });
            });
        }
        $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
        $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
        $('#ir-check-box').addClass('no-visible');
    };

    $scope.showPostBox = function(type) {

        if (type == 'message') {
            if($('#ir-new-post-message').hasClass('ir-new-post-message-visible')) {
                $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
                $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
            } else {
                $('#ir-new-post-message').addClass('ir-new-post-message-visible');
                $('#ir-new-post-container .ir-new-post-box').addClass('ir-new-post-box-visible');
            }
        } else {
            if ($scope.post)
                $scope.post.message = '';
            $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
            $('#ir-new-post-container .ir-new-post-box').addClass('ir-new-post-box-visible');
        }
        $('#ir-feed-filters').css({
            "overflow": "hidden"
        });
        $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
        $('#ir-check-box').addClass('no-visible');

        $scope.setNewPostLabel();
    };

    $scope.showAllFeedImages = function(feed_item){
        feed_item.files_limit=feed_item.files.length;
    };

}])
