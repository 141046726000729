//Global Variables
var transitionEvent;
var md;
//Mobile Detect
md = new MobileDetect(window.navigator.userAgent);

var file_icons = {
    'docx': 'file-word.png',
    'xlsx': 'file-excel.png',
    '.doc': 'file-word.png',
    '.xls': 'file-word.png',
    '.pdf': 'file-pdf.png',
    '.dwg': 'file-dwg.png'
};

$(function() {
    //Material Design Init
    $('select').material_select();

    //Transitions
    transitionEvent = whichTransitionEvent();

    //Navbar
    if (md.mobile()) {
        $("#ir-main-nav").headroom({
            offset: 100,
            onPin: function () {
                $("#ir-main-nav").removeClass('nav-unpinned');
                $("#ir-main-nav").addClass('nav-pinned');
                if (md.phone()) {
                    $('#ir-new-post-container').removeClass('ir-buttons-hidden');
                    $('.ir-filter-feed-floating-btns').addClass('hide-on-small-and-down');
                }
                $('#ir-feed-filters').removeClass('ir-fixed-filters');
            },
            onUnpin: function () {
                var hide_nav = true;
                if (md.phone()) {
                    if(!$('#ir-new-post-container .ir-new-post-box').hasClass('ir-new-post-box-visible')) {
                        $('#ir-new-post-container').addClass('ir-buttons-hidden');
                        if ($('#ir-feed-filters').hasClass('ir-filters-visible')) {
                            $('#ir-feed-filters').css({
                                "overflow": "hidden"
                            });
                        }
                        $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
                        $('#ir-new-post-message').removeClass('ir-new-post-message-visible');
                        $('#ir-new-post-container .ir-new-post-box').removeClass('ir-new-post-box-visible');
                    } else {
                        hide_nav = false;
                    }
                    $('.ir-filter-feed-floating-btns').removeClass('hide-on-small-and-down');
                }
                $('#ir-feed-filters').addClass('ir-fixed-filters');
                
                if (hide_nav) {
                    $("#ir-main-nav").removeClass('nav-pinned');
                    $("#ir-main-nav").addClass('nav-unpinned');
                }
            }
        });

        $(window).resize(function () {
            if ($(window).width() <= 600) {
                $('#ir-feed-filters').css({
                    "overflow": "hidden"
                });
            } else {
                $('#ir-feed-filters').css({
                    "overflow": "visible"
                });
            }

            $('.search-input').blur();
            $('#ir-feed-filters, #ir-new-post-container').removeClass('ir-filters-visible');
        });

    }

});

function split(val) {
    return val.split(/,\s*/);
}

function extractLast(term) {
    return split(term).pop();
}

function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}

function countInArray(what, array) {
    var count = 0;
    for (var i = 0; i < array.length; i++) {
        if (array[i] === what) {
            count++;
        }
    }
    return count;
}

function success_toast(message) {
    Materialize.toast(message, 5000, 'green');
}
function error_toast(message) {
    if (typeof message === 'string') {
        Materialize.toast(message, 600000, 'red');
    } else {
        Materialize.toast('<span>'+message.join('<br>')+'</span>', 600000, 'red');
    }
}
function warning_toast(message) {
    Materialize.toast(message, 5000, 'amber');
}

function ucfirst(str) {
    //  discuss at: http://phpjs.org/functions/ucfirst/
    // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // bugfixed by: Onno Marsman
    // improved by: Brett Zamir (http://brett-zamir.me)
    //   example 1: ucfirst('kevin van zonneveld');
    //   returns 1: 'Kevin van zonneveld'

    str += '';
    var f = str.charAt(0)
        .toUpperCase();
    return f + str.substr(1);
}

function convertToDataURLviaCanvas(url, callback, outputFormat){
    var img = new Image();
    img.onload = function(){
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
    };
    img.src = url;
}

// Function from David Walsh: http://davidwalsh.name/css-animation-callback
function whichTransitionEvent(){
    var t,
        el = document.createElement("fakeelement");

    var transitions = {
        "transition"      : "transitionend",
        "OTransition"     : "oTransitionEnd",
        "MozTransition"   : "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    };

    for (t in transitions){
        if (el.style[t] !== undefined){
            return transitions[t];
        }
    }
}

$('.button-collapse').sideNav({
        menuWidth: 300,
        edge: 'right',
        closeOnClick: true,
        draggable: true
    }
);
