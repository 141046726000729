'use strict';

/* Directives */
angular.module('ireactApp.directives', [])
    .directive('backButton', function () {
        return {
            restrict: 'A',

            link: function (scope, element, attrs) {
                element.bind('click', goBack);

                function goBack() {
                    history.back();
                    scope.$apply();
                }
            }
        };
    })
    .directive('pressEnter', function () {
        return function (scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if(event.which === 13) {
                    scope.$apply(function (){
                        scope.$eval(attrs.pressEnter);
                    });

                    event.preventDefault();
                }
            });
        };
    })
    .directive('irAutocomplete', function () {
        return {
            restrict: "A",
            require: 'ngModel',
            scope: {
                ngModel: '=',
                irAutocompleteModel: '=',
                irAutocompleteSource: "&",
                irAutocompleteModelDepends: "="
            },
            link: function (scope, element, attrs) {
                var options = {
                    search: function (event, ui) {
                        // custom minLength
                        var term = extractLast(scope.ngModel);
                        if (term.length < 1) {
                            return false;
                        }
                    },
                    response: function( event, ui ) {
                        if(scope.ngModel) {
                            var terms = split(scope.ngModel);
                            var indexes = [];
                            for (var i = 0; i < ui.content.length; i++) {
                                //verify if term is not already added
                                if (inArray(ui.content[i].value, terms)) {
                                    //verify if is the current term adding
                                    if (terms[terms.length -1] === ui.content[i].value &&
                                        scope.ngModel.slice(-1) !== ',' &&
                                        countInArray(ui.content[i].value, terms) === 1) {
                                        continue;
                                    }
                                    indexes.push(i);
                                }
                            }

                            for (var i = indexes.length -1 ; i >= 0; i--) {
                                ui.content.splice(indexes[i], 1);
                            }
                        }
                    },
                    focus: function () {
                        // prevent value inserted on focus
                        return false;
                    },
                    select: function (event, ui) {
                        var terms = split(scope.ngModel);
                        scope.irAutocompleteModel.push(Object.assign({},ui.item));
                        // remove the current input
                        terms.pop();
                        // add the selected item
                        terms.push(ui.item.value);
                        // add placeholder to get the comma-and-space at the end
                        terms.push("");
                        ui.item.value = terms.join(", ");
                        scope.ngModel = terms.join(", ");
                    },
                    change: function( event, ui ) {
                        if(scope.ngModel) {
                            var terms = split(scope.ngModel);
                            angular.forEach(scope.irAutocompleteModel, function (entity, key) {
                                if (!inArray(entity.value, terms)) {
                                    scope.irAutocompleteModel.splice(key, 1);                                                                        
                                }
                            });
                        } else {
                            scope.irAutocompleteModel.splice(0, scope.irAutocompleteModel.length);
                        }
                    },
                    source: function (request, response) {
                        if (scope.irAutocompleteModelDepends != undefined) {
                            request.model_depends = scope.irAutocompleteModelDepends;
                        }
                        scope.irAutocompleteSource({
                            request: request,
                            response: response
                        }).then(function(ret){
                            response = ret
                        });
                    }                    
                };

                // extend options from controller
                options = angular.extend(options);

                var object = element.autocomplete(options);
            }
        };
    })
    .directive('uniqueField', [function() {
        return {
            require: 'ngModel',
            link: function(scope, ele, attrs, c) {
                scope.$watch(attrs.ngModel, function() {

                    if (attrs.uniqueFieldName) {
                        var repeated = 0;
                        angular.forEach(scope.$eval(attrs.uniqueField), function (entity, key) {
                            if (scope.$eval(attrs.ngModel) && entity[attrs.uniqueFieldName] && scope.$eval(attrs.ngModel).toString().trim() == entity[attrs.uniqueFieldName].toString().trim()) {
                                repeated++;
                            }
                        });

                        if (repeated > 1) {
                            c.$setValidity('unique', false);
                        } else {
                            c.$setValidity('unique', true);
                        }
                    }
                });
            }
        }
    }])
    .directive('uniqueUsername', [function() {
        return {
            require: 'ngModel',
            link: function(scope, ele, attrs, c) {
                scope.$watch(attrs.ngModel, function() {
                    var repeated = 0;
                    angular.forEach(scope.$eval(attrs.uniqueUsername), function (user, key) {
                        if (scope.$eval(attrs.ngModel) && scope.$eval(attrs.ngModel).trim() == user.username.trim()) {
                            repeated++;
                        }
                    });

                    if (repeated > 1) {
                        c.$setValidity('unique', false);
                    } else {
                        c.$setValidity('unique', true);
                    }
                });
            }
        }
    }])
    .directive('uniqueEmail', [function() {
        return {
            require: 'ngModel',
            link: function(scope, ele, attrs, c) {
                scope.$watch(attrs.ngModel, function() {
                    var repeated = 0;
                    angular.forEach(scope.$eval(attrs.uniqueEmail), function (user, key) {
                        if (scope.$eval(attrs.ngModel) && scope.$eval(attrs.ngModel).trim() == user.email.trim()) {
                            repeated++;
                        }
                    });

                    if (repeated > 1) {
                        c.$setValidity('unique', false);
                    } else {
                        c.$setValidity('unique', true);
                    }
                });
            }
        }
    }])
    .directive('passwordCheck', [function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var firstPassword = '#' + attrs.passwordCheck;
                elem.add(firstPassword).on('keyup', function () {
                    scope.$apply(function () {
                        var v = elem.val()===$(firstPassword).val();
                        ctrl.$setValidity('pwmatch', v);
                    });
                });
            }
        }
    }])
    .directive('repeatDone', function($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                if (scope.$last === true) {
                    $timeout(function () {
                        scope.$eval(attrs.repeatDone);
                    });
                }
            }
        }
    });
