angular.module('ireactApp.tags', ['ireactApp.controllers'])

    /**
     * Project Index
     */
    .controller('TagsIndexController', ['$scope', '$http', function ($scope, $http) {

        $scope.tags = [];
        $scope.loading = true;
        $scope.saving = false;
        $scope.order_dir = 'asc';

        $scope.getTags = function()
        {
            $scope.loading = true;
            $http({
                method : "GET",
                url : laroute.route('tags.get_data', {
                    order_dir: $scope.order_dir
                })
            }).success(function(data){
                $scope.tags = data.tags;
                $scope.loading = false;
            });
        };

        $scope.orderBy = function(order_by)
        {
            if (order_by == $scope.order_by) {
                $scope.order_dir = $scope.order_dir == 'asc' ? 'desc' : 'asc';
            } else {
                $scope.order_dir = 'asc';
            }
            $scope.order_by = order_by;
            $scope.getTags();
        };

        $scope.saveTags = function() {

            if ($scope.frmManageTags.$valid) {
                $scope.saving = true;
                $http({
                    method: "PUT",
                    url: laroute.route('tags.update_all'),
                    data: {
                        tags: $scope.tags,
                        order_dir: $scope.order_dir
                    }
                }).success(function (data) {
                    $scope.tags = data.tags;
                    $scope.saving = false;
                    success_toast('Folders updated');
                }).error(function (data){
                    $scope.saving = false;
                    angular.forEach(data.errors, function (errors, key) {
                        error_toast(errors.errors);
                    });
                });
            } else {
                $scope.frmManageTags.submitted = true;
            }
        };

        $scope.deleteTag = function(id){
            $("#delete-tag").openModal();
            $scope.toDeleteTag = id;
        };

        $scope.confirmDeleteTag = function(id){
            $scope.saving = true;
            $http({
                method: "DELETE",
                url: laroute.route('tags.destroy', {tags:$scope.toDeleteTag })
            }).success(function (data) {
                $scope.saving = false;
                $scope.getTags();
                success_toast('Tag deleted');
            }).error(function(error){
                $scope.saving = false;
                error_toast(error.message);
            });
        };

        $scope.getTags();
    }])