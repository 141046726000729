'use strict';

/* Filters */
angular.module('ireactApp.filters', [])
    .filter('ageFilter', function() {
        function calculateAge(birthday) { // birthday is a date
            birthday = new Date(birthday);
            if (birthday){
                var ageDifMs = Date.now() - birthday.getTime();
                var ageDate = new Date(ageDifMs); // miliseconds from epoch
                var age = Math.abs(ageDate.getUTCFullYear() - 1970);
                return isNaN(age) ? '' : age;
            }
        }

        return function(birthdate) {
            return birthdate ? calculateAge(birthdate) : '';
        };
    })
    .filter('imageWithSize', function() {
        return function(url, size) {
            if(url) {
                url = url.replace('_normal', '');
                var parts = url.split('.');
                var extension = parts[parts.length - 1];

                var new_image = url.replace("." + extension, (size ? '_' + size : '') + "." + extension);

                return new_image;
            }
            return '';
        };
    })
    .filter('formatDate', function() {
        return function(date, format) {
            if(date) {
                var moment_date = moment(date);
                return moment_date.format(format);
            }
            return '';
        };
    })
    .filter('unsafe', function($sce) {
        return function(val) {
            if (val) {
                return $sce.trustAsHtml(val);
            }
            return val;
        };
        //return function(val) {
        //    return $sce.trustAsHtml(val);
        //};
    });



